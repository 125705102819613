import React, { useState } from 'react';
import { useEffect } from 'react';
import { Label } from 'reactstrap';
import LightBox from '../../layout/LightBox';
import ChangeRequestTypeModal from '../modals/ChangeRequestTypeModal';
import RequestDetail from '../requestdetails/RequestDetail';
import ImageModal from './ImageModal';
import QuestionWrapper from './QuestionWrapper';
import styles from './questionWrapper.module.scss'

var CryptoJS = require("crypto-js");

export default function RequestDetailsCompleted(props) {
    const [showChangeTypeModal, setShowChangeTypeModal] = useState(false);
    const requestType = props.requestTypes?.filter(x => x.id === props.genericRequestFormModel.requestTypeId)[0];
    const [modalContent, setModalContent] = useState('');
    const [modalTitle, setModalTitle] = useState('');
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        window.handleImageClick1 = handleImageClick1;
    },[props.genericRequestFormModel])

    function decryptDetailResponse(detailModel) {
        let key = process.env.REACT_APP_GENERIC_REQUESTS_CLIENT_SIDE_KEY + detailModel.detailId;

        var bytes = CryptoJS.AES.decrypt(detailModel.response, key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }

    function addLineBreaks(response) {
        if (response && response.length > 0) {
            response = response.split(';')
            response = response.map((line, i) => <span key={i}>{line}</span>)
        }
        return response;
    }

    const getTextWithoutImages = (comment) => {
        return comment.replace(/<img[^>]*>/g, '').trim();
    };

    function updateOuterForm(form, pageAlert) {
        setShowChangeTypeModal(false);
        props.onUpdateRequestType(form,pageAlert)
    }

    function processCommentText(comment) {
        let processedText = comment.replace(/<img([^>]+)>/g, (match, g1) => {
            return `<img ${g1} class="grfImageThumbnail" onclick="handleImageClick('imageHolder', '', '', 0)">`;
        });

        processedText = linkify(processedText);

        processedText = processedText.replaceAll('<p><br></p>', '<br />')
            .replaceAll('<p>', '<br>')
            .replaceAll('</p>', '')
            .replaceAll(/<\/br><br>/g, '</br>')
            .replace(/^(<br\s*\/?>)+/, '');

        return processedText;
    }

    const handleImageClick1 = (imageUrl, commenterName, date, commentIndex) => {
        let content = document.getElementById(imageUrl).innerHTML.replaceAll('grfImageThumbnail', 'grfImageNonThumbnail');
        let modalTitle = `Additional Details Provided On Form Submission By ${props.genericRequestFormModel.requesterName} on ${new Date(props.genericRequestFormModel.requestDateTime).toLocaleDateString()}`;
        setModalContent(content);
        setModalTitle(modalTitle)
        setShowModal(true);
    };

    function linkify(text) {
        const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;

        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = text;

        const processNodes = (node) => {
            if (node.nodeType === Node.TEXT_NODE) {
                const matches = node.nodeValue.match(urlRegex);

                if (matches) {
                    const fragment = document.createDocumentFragment();
                    let lastIndex = 0;

                    matches.forEach(match => {
                        const index = node.nodeValue.indexOf(match, lastIndex);

                        if (index > lastIndex) {
                            fragment.appendChild(document.createTextNode(node.nodeValue.substring(lastIndex, index)));
                        }

                        const a = document.createElement('a');
                        const hyperlink = match.startsWith('http') ? match : 'http://' + match;
                        a.href = hyperlink;
                        a.target = "_blank";
                        a.rel = "noopener noreferrer";
                        a.textContent = match;
                        a.style.color = '#d59038';

                        fragment.appendChild(a);

                        lastIndex = index + match.length;
                    });

                    if (lastIndex < node.nodeValue.length) {
                        fragment.appendChild(document.createTextNode(node.nodeValue.substring(lastIndex)));
                    }

                    node.replaceWith(fragment);
                }
            } else if (node.nodeType === Node.ELEMENT_NODE && node.nodeName !== 'IMG') {
                Array.from(node.childNodes).forEach(processNodes);
            }
        };

        Array.from(tempDiv.childNodes).forEach(processNodes);

        return tempDiv.innerHTML;
    }

    return (
        <>
            <QuestionWrapper>
                <div to='selectRequestType'>Request Type
                    {props.isAdmin && props.formPreferences?.allowReclassification &&
                        <span onClick={() => setShowChangeTypeModal(true)} className={styles.changeLabel}><br />Change</span>
                    }
                </div>
                <LightBox id='selectRequestType'>
                    {requestType?.requestType1}
                </LightBox>
            </QuestionWrapper>
            {props.genericRequestFormModel.genericRequestFormDetails.map(p =>
                <RequestDetail key={p.id} RequestTypeDetail={p.detail} isAdmin={props.isAdmin} onShowChangePropertiesModal={props.onShowChangePropertiesModal} onShowChangeEmailModal={props.onShowChangeEmailModal} genericRequestFormModel={props.genericRequestFormModel}>
                    <LightBox>
                        {p.isEncrypted ? decryptDetailResponse(p) : addLineBreaks(p.response)}
                    </LightBox>
                </RequestDetail>
            )}
            <QuestionWrapper >
                <Label to='txtOtherRequestInfo'>Additional Information or Details
                    {props.genericRequestFormModel.additionalDetails.toLowerCase().includes('img') &&
                    <><br /><br /><i>Click on an image thumbnail to view the bigger image ---></i></>
                }
                </Label>
                <LightBox id='txtOtherRequestInfo'>
                    <span id="imageHolder" dangerouslySetInnerHTML={{
                        __html: processCommentText(props.genericRequestFormModel.additionalDetails)
                            //return `<img ${g1} class="grfImageThumbnail" onclick="window.handleImageClick1('${"imageHolder"}', \`${text}\`)">`;                   
                    }}></span>
                </LightBox>
            </QuestionWrapper>

            <ChangeRequestTypeModal isOpen={showChangeTypeModal} toggle={() => setShowChangeTypeModal(!showChangeTypeModal)} genericRequestFormModel={props.genericRequestFormModel} onUpdateRequestType={updateOuterForm} canReclassifyTo={props.formPreferences?.canReclassifyTo} requestTypes={props.requestTypes} propertyList={props.propertyList}/>

            <ImageModal
                isOpen={showModal}
                toggle={() => setShowModal(!showModal)}
                modalContent={modalContent}
                modalTitle={modalTitle}
            />

        </>
    );

}
