import React, { useEffect, useState } from 'react';
import { axios } from 'react-mimg';
import RequestDetail from './RequestDetail';
import CreatableSelect from 'react-select/creatable';
import { getEmployeeSecurity } from '../../../functions/permissions';
import styles from './requestDetailMyEmployeePicker.module.scss';
import { Input, FormGroup } from 'reactstrap';

export default function RequestDetailMyEmployeePicker(props) {
    const security = getEmployeeSecurity();
    const [employeeList, setEmployeeList] = useState([])
    const [selectedOption, setSelectedOption] = useState()
    const [employeeOptions, setEmployeeOptions] = useState([])
    const [showInactive, setShowInactive] = useState(false);

    useEffect(() => {
        if (props.isAdmin) {
            axios.get('api/propertyEmployees/getAll')
                .then(res => {
                    let options = []
                    let allEmployees = res.data.filter(x => x.prismId !== null || x.documentCount > 0);
                    allEmployees = showInactive ? allEmployees : allEmployees.filter(x => x.isActive);
                    allEmployees.forEach(emp => { options.push({ value: emp.propertyEmployeesId, label: getEmployeeNameLabel(emp) }) });
                    setEmployeeList(allEmployees);
                    setEmployeeOptions(options);
                })
        } else if (props.myEmployees && props.myEmployees.length > 0) {
            let options = []
            let myEmployees = props.myEmployees;
            myEmployees = showInactive ? myEmployees : myEmployees.filter(x => x.isActive);
            myEmployees.forEach(emp => { options.push({ value: emp.propertyEmployeesId, label: getEmployeeNameLabel(emp) }) });
            setEmployeeList(myEmployees);
            setEmployeeOptions(options);
    
        } else {
            let options = []
            options.push({ value: security.propertyEmployeesId, label: security.employeeFullName + ' - ' + security.employeePosition + '(PrismId: ' + security.empPrismId + ', WebsiteId: ' + security.empID + ')' });

            axios.get('api/PropertyEmployees/GetMyEmployees?employeeType=all').then((response) => {
                let employees = response.data.sort((a, b) => a.employeeName > b.employeeName ? 1 : -1);
                employees = showInactive ? employees : employees.filter(x=> x.isActive);
                employees.forEach(emp => { options.push({ value: emp.propertyEmployeesId, label: getEmployeeNameLabel(emp) }) });
                setEmployeeList(employees);
                setEmployeeOptions(options);
            })
        }
    }, [props.isAdmin, props.myEmployees, showInactive])



    function getEmployeeNameLabel(emp) {
        if (emp.isActive) {
            return emp.employeeName + ' - ' + emp.position + ' (PrismId:' + emp.prismId + ', WebsiteId: ' + emp.propertyEmployeesId + ')';
        } else {
            return emp.employeeName + ' - ' + emp.position + ' (PrismId:' + emp.prismId + ', Inactive: ' + new Date(emp.terminationDate).toLocaleDateString() + ') ';
        }
    }

    function handleChange(option) {
        setSelectedOption(option)
        props.onSelectEmployee && props.onSelectEmployee(employeeList.filter(x => x.propertyEmployeesId === option?.value)[0]);
    }

    function handleCreate(inputValue) {
        setTimeout(() => {
            const newOption = { label: inputValue, value: inputValue }
            setEmployeeOptions((prev) => [...prev, newOption]);
            setSelectedOption(newOption)
        }, 300);
    };

    if (!employeeOptions)
        return <div>Loading...</div>

    return (
        <div>

            {!props.isAdmin && <div className={styles.note}>Only your employees are available in the dropdown list</div>}
            <RequestDetail RequestTypeDetail={props.RequestTypeDetail}>

                <CreatableSelect
                    isClearable
                    placeholder={props.RequestTypeDetail.placeholder}
                    options={employeeOptions}
                    onChange={handleChange}
                    value={selectedOption}
                    name='employeePicker'
                    onCreateOption={handleCreate}
                />
                <div className='genericRequestDetailQuestionLabel'>
                    <div className='genericRequestDetailInput'>
                        <FormGroup switch>
                            <Input type="switch" checked={showInactive} onChange={(e) => setShowInactive(e.target.checked)}></Input>Show Inactive Employees
                        </FormGroup>
                    </div>
                </div>

            </RequestDetail>
        </div>
    );
}
