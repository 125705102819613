import React, { useState, useEffect } from 'react';
import flexStyles from '../../../styles/flex.module.scss';
import styles from './GenericRequestForm.module.scss';
import { axios, Button } from 'react-mimg';
import { getEmployeeSecurity } from '../../../functions/permissions';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert, Input, Label } from 'reactstrap';
import EmployeeFilter from '../../layout/Filter/EmployeeFilter';
import { createComment } from '../../../functions/formHelpers/genericRequestFormHelper';
import Required from '../../layout/Required';
import Spinner from '../../layout/Spinner';
import RequestHistory from '../modals/RequestHistory';
import SubmitOnBehalfOfModal from '../modals/SubmitOnBehalfOfModal';

export default function RequestorInfo(props) {

    const [requesterEmployeeCard, setRequesterEmployeeCard] = useState(null);
    const [showOverrideEmployeeModal, setShowOverrideEmployeeModal] = useState(false);
    const [allEmployeeOptions, setAllEmployeeOptions] = useState([])
    const [selectedOverrideEmployee, setSelectedOverrideEmployee] = useState()
    const security = getEmployeeSecurity();
    const showTelephoneHyperLink = security.employeePosition === "IT Senior" || security.employeePosition === "IT";
    const [showChangeSubmitterModal, setShowChangeSubmitterModal] = useState(false);
    const [employeeList, setEmployeeList] = useState([]);
    const [newRequesterEmpId, setNewRequesterEmpId] = useState(0);
    const [changeSubmitterAlert, setChangeSubmitterAlert] = useState(false);
    const [changeSubmitterComment, setChangeSubmitterComment] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showTicketHistoryCanvas, setShowTicketHistoryCanvas] = useState(false);

    useEffect(() => {
        if (props.genericRequestFormModel.id > 0) {
            axios.get(`api/PropertyEmployees/GetSingleEmployeeCard?PropertyEmployeesId=${props.genericRequestFormModel.requesterEmpId}`).then(response => {
                setRequesterEmployeeCard(response.data);
            })
        }
    }, [])

    useEffect(() => {
        if (showChangeSubmitterModal && props.formPreferences.allowChangeSubmitter) {
            if (props.formPreferences.requiresManagerAccess) {
                axios.get('api/PropertyEmployees/GetEmployee/' + props.genericRequestFormModel.genericRequestFormEmployees[0].propertyEmployeesId).then(r => {
                    setEmployeeList(r.data.supervisors);
                })
            } else {
                setEmployeeList();
            }
        }
    }, [showChangeSubmitterModal, props.genericRequestFormModel])

    useEffect(() => {
    }, [newRequesterEmpId])

    function getEmployeePhoneNumber() {
        if (props.employeePhone) {
            return props.employeePhone
        } else if (requesterEmployeeCard !== null) {
            if (requesterEmployeeCard.phone?.length >= 10) {
                return requesterEmployeeCard.phone
            } else {
                if (requesterEmployeeCard.cellPhone?.length >= 10) {
                    return requesterEmployeeCard.cellPhone
                } else {
                    return props.genericRequestFormModel.requesterPhone;
                }
            }
        } else {
            return props.employeeSecurity.employeePhone;
        }
    }

    function getEmail() {
        if (requesterEmployeeCard !== null) {
            return requesterEmployeeCard.email;
        } else {
            return props.employeeSecurity.employeeEmail;
        }
    }

    function onUpdateEmployee(employeeCard) {
        setRequesterEmployeeCard(employeeCard);
        props.onUpdateActuallyForEmployeeCard(employeeCard);
        setShowOverrideEmployeeModal(false);
    }

    function updateSubmitter() {
        setChangeSubmitterAlert(false)
        setIsLoading(true)
    
        if (newRequesterEmpId > 0) {
            axios.get('api/PropertyEmployees/GetSingleEmployeeCard?PropertyEmployeesId=' + newRequesterEmpId).then(response => {

                let newEmp = response.data
                setRequesterEmployeeCard(newEmp);
                props.onUpdateActuallyForEmployeeCard(newEmp);
                let genericRequestFormModel = props.genericRequestFormModel
                let genericRequestComments = genericRequestFormModel.genericRequestFormComments;
                let comment = createComment(security.empID, `Requester was changed from ${genericRequestFormModel.requesterName} to ${newEmp.employeeName}. Additional Info: ${changeSubmitterComment}`)
                genericRequestComments.push(comment);

                genericRequestFormModel.originalRequesterName = genericRequestFormModel.requesterName;
                genericRequestFormModel.originalRequesterEmpId = genericRequestFormModel.requesterEmpId;
                genericRequestFormModel.requesterChangedByEmpName = security.employeeFullName;

                genericRequestFormModel.genericRequestFormComments = genericRequestComments;
                genericRequestFormModel.requesterName = newEmp.employeeName;
                genericRequestFormModel.requesterEmpId = parseInt(newEmp.propertyEmployeesId);
                genericRequestFormModel.requesterPropertyName = newEmp.homeProperty;
                genericRequestFormModel.requesterSiteId = parseInt(newEmp.siteId);
                genericRequestFormModel.requesterEmail = newEmp.email;
                genericRequestFormModel.requesterPhone = newEmp.phone;
                genericRequestFormModel.requesterPosition = newEmp.position;

                axios.put("api/GenericRequestForm/UpdateRequest?updateAction=changeSubmitter", genericRequestFormModel).then((response) => {
                    genericRequestFormModel = response.data;
                    let pageAlert = { AlertText: "", AlertColor: "success" };
                    props.onSave(genericRequestFormModel); //no emails sent
                    setShowChangeSubmitterModal(false);
                    setIsLoading(false);
                })

            });
        } else {
            setChangeSubmitterAlert(true)
        }
    }

    return (
        <div className={flexStyles.paddingBottom10}>
            <h4 className={styles.sectionHeader}>Requestor Info</h4>
            {props.formPreferences.allowSubmitOnBehalfOf && props.isAdmin && !props.genericRequestFormModel.id &&
                <span className={styles.spanViewEmployeeProfile} onClick={() => setShowOverrideEmployeeModal(true)}>Submit on behalf of someone else<br /></span>
            }
            Requestor Name: <span id="spanRequestorName">{requesterEmployeeCard !== null ? requesterEmployeeCard.employeeName : props.employeeSecurity.employeeFullName}</span>
            {props.formPreferences.allowChangeSubmitter && props.isAdmin && props.genericRequestFormModel.id
                ? <span className={styles.spanChangeSubmitter} onClick={() => setShowChangeSubmitterModal(true)}>Change Submitter</span>
                : <></>
            }
            <br />
            Requestor Position: <span id="spanRequestorPosition"> {requesterEmployeeCard !== null ? requesterEmployeeCard.officialJobTitle : props.employeeSecurity.employeePosition}</span><br />
            Requestor Email: <a className={styles.spanViewEmployeeProfile} id="spanRequestorEmail" href={'mailto:' + getEmail()}>{getEmail()}</a><br />
            Requestor Phone: &nbsp;
            {showTelephoneHyperLink
                ? <span><a className={styles.spanViewEmployeeProfile} id="spanRequestorEmail" href={'tel:' + getEmployeePhoneNumber()}>{getEmployeePhoneNumber()}</a><br /></span>
                : <span>{getEmployeePhoneNumber()}</span>}<br />
            {props.genericRequestFormModel.submittedOnBehalfOfUser &&
                <span><i>**Form submitted on above user's behalf by {props.genericRequestFormModel.submittedOnBehalfOfUserByEmpName}</i><br /></span>
            }
            {props.genericRequestFormModel.originalRequesterName &&
                <span><i>**Requester changed from {props.genericRequestFormModel.originalRequesterName} to above user by {props.genericRequestFormModel.requesterChangedByEmpName} </i><br /></span>
            }
            {props.genericRequestFormModel.requesterEmpId
                ? <a className={styles.spanViewEmployeeProfile} href={`profiles/employee?id=${props.genericRequestFormModel.requesterEmpId}`} target="_blank" rel="noreferrer">View Profile</a>
                : <></>
            }
            {props.isAdmin && props.genericRequestFormModel.id
                ? <span className={styles.spanViewEmployeeProfile} onClick={() => setShowTicketHistoryCanvas(true)}>View Employee Ticket History</span>
                : <></>
            }

            <SubmitOnBehalfOfModal
                isOpen={showOverrideEmployeeModal}
                toggle={() => setShowOverrideEmployeeModal(!showOverrideEmployeeModal)}
                onUpdateEmployee={onUpdateEmployee}
                formPreferences={props.formPreferences}
            />

            <RequestHistory
                isOpen={showTicketHistoryCanvas}
                toggle={() => setShowTicketHistoryCanvas(!showTicketHistoryCanvas)}
                genericRequestFormModel={props.genericRequestFormModel}
                employee={requesterEmployeeCard !== null ? requesterEmployeeCard.employeeName : props.employeeSecurity.employeeFullName}
            />

            <Modal className="modal-lg" isOpen={showChangeSubmitterModal} toggle={() => setShowChangeSubmitterModal(!showChangeSubmitterModal)}>
                <ModalHeader toggle={() => setShowChangeSubmitterModal(!showChangeSubmitterModal)}>
                    Select the Employee You Want to Set As the New Submitter
                </ModalHeader>
                <ModalBody>
                    {changeSubmitterAlert && <Alert color='danger'>You have not selected a new submitter</Alert>}
                    {props.formPreferences.requiresManagerAccess && <div>This form requires a manager of the selected employee to be the submitter</div>}

                    <Label className={styles.label}>New Submitter<Required /></Label>
                    <EmployeeFilter employeeList={employeeList} onChange={(e) => (setNewRequesterEmpId(e), setChangeSubmitterAlert(false))} loadDefaultOptions /><br />
                    <Label className={styles.label}>Comment</Label>
                    <Input type='textbox' placeholder="Reason For Making the Change" onChange={(e) => setChangeSubmitterComment(e.target.value)} />
                </ModalBody>
                <ModalFooter>
                    <Button className={isLoading ? styles.isLoading : null} transparent onClick={() => setShowChangeSubmitterModal(false)}>Close</Button>
                    <Button className={isLoading ? styles.isLoading : null} disabled={isLoading} onClick={updateSubmitter}>Update</Button>
                    <div>{isLoading && <Spinner />}</div>
                </ModalFooter>
            </Modal>

        </div>
    );
}
