import React, { useEffect, useState } from 'react'
import { axios } from 'react-mimg';
import AsyncSelect from "react-select/async";

export default function EmployeeFilter(props) {
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState();

    useEffect(() => {
        let emp = props.defaultEmployee;
        if (emp) {
            setSelected({ label: emp.employeeName + ' (' + emp.prismId + ')', value: emp.propertyEmployeesId })
        }
        if (props.employeeList) {
            var allEmployeesWithPrism = props.employeeList.filter(x => x.prismId !== null);
            var options = allEmployeesWithPrism.map(x => ({ label: x.employeeName + ' (' + x.prismId + ')', value: x.propertyEmployeesId }))
            setOptions(options);
        }
        else if (!props.isDisabled) {
            axios.get('api/propertyEmployees/getAll')
                .then(res => {
                    var allEmployeesWithPrism = res.data.filter(x => x.prismId !== null || x.documentCount > 0);
                    var options = allEmployeesWithPrism.map(x => ({ label: x.employeeName + ' (' + (x.prismId !== null ? x.prismId : 'No Prism Id Mapped') + ')', value: x.propertyEmployeesId }))
                    setOptions(options)
                })
        }
    }, [props.defaultEmployee, props.isDisabled, props.employeeList])

    function filter(searchTerm) {
        var search = searchTerm.toLowerCase();

        return options.filter(emp => {
            var label = emp.label.toLowerCase();
            var nameArray = label.split(' ');
            return nameArray.some(x => x.startsWith(search) || x.startsWith('(' + search)) || label.startsWith(search)
        });
    }

    function loadOptions(search, callback) {
        setTimeout(() => {
            callback(filter(search));
        }, 500);
    }

    function onChange(option) {
        setSelected(option)
        if (props.isMulti)
            props.onChange(option?.map(x => x.value))
        else
            props.onChange(option?.value)
    }

    return (
        <AsyncSelect
            defaultOptions={props.loadDefaultOptions ? options : false }
            isDisabled={props.isDisabled}
            id='employeeSelect'
            placeholder='Employee Name'
            isMulti={props.isMulti}
            cacheOptions
            isClearable
            onChange={onChange}
            value={selected}
            loadOptions={loadOptions}
            noOptionsMessage={({ inputValue }) => !inputValue ? 'Type employee name to search' : 'No result found'}
        />
    )
}