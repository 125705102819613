import React, { useState, useEffect } from 'react';
import { Input, UncontrolledPopover, PopoverHeader, PopoverBody, Label } from 'reactstrap';
import LightBox from '../../layout/LightBox';
import Required from '../../layout/Required';
import QuestionWrapper from '../formelements/QuestionWrapper';
import RequestDetail from './RequestDetail';
import styles from './RequestDetailAssociatedDocuments.module.scss';

export default function RequestDetailAssociatedDocuments(props) {

    const [showPopover, setShowPopover] = useState(false)
    const [applicationRequired, setApplicationRequired] = useState(false)
    const [LVRequired, setLVRequired] = useState(false)
    const [transferFormRequired, setTranferFormRequired] = useState(false)
    const [criteriaRequired, setCriteriaRequired] = useState(false)
    const [rmAddendumRequired, setRmAddendumRequired] = useState(false)

    useEffect(() => { //the LTO Form requires that the application document is required when the form is for a new move-in.  As the GRF system was designed getting values from the DOM and not 100% react-friendly, we need to add the listener and update whether the docs are required that way.
        let addWatchToIds = process.env.REACT_APP_LTO_FORM_TYPE_DETAILS.split(',');
        addWatchToIds.forEach((x) => {
            if (document.getElementById(`detail_input_${x}`)) {
                document.getElementById(`detail_input_${x}`).addEventListener("change", function () {
                    let selectedValue = this.children[0].value;
                    if (!['renewal', 'lto (existing resident)', 'roommate change (add)'].includes(selectedValue.toLowerCase()) /*selectedValue.toLowerCase() !== 'renewal'*/) {
                        setApplicationRequired(true);
                    } else {
                        setApplicationRequired(false);
                    }

                    if (!['renewal', 'transfer (on-site)', 'lto (existing resident)', 'roommate change (remove)'].includes(selectedValue.toLowerCase())) {
                        setLVRequired(true);
                    } else {
                        setLVRequired(false);
                    }

                    if (['transfer (on-site)'].includes(selectedValue.toLowerCase())) {
                        setTranferFormRequired(true);
                    } else {
                        setTranferFormRequired(false);
                    }

                    if (['lto (new move-in)', 'transfer (sister property)'].includes(selectedValue.toLowerCase())) {
                        setCriteriaRequired(true)
                    } else {
                        setCriteriaRequired(false)
                    }

                    if (['roommate change (remove)'].includes(selectedValue.toLowerCase())) {
                        setRmAddendumRequired(true)
                    } else {
                        setRmAddendumRequired(false)
                    }
                })
            }
        })

    },[])

    //add on change listeners to the relevant details
    function documentIsRequired(document) {
 
        if (['Application'].includes(document.documentDescription)) {
            if (applicationRequired) {
                return true;
            }
        }
        if (['Landlord Verification'].includes(document.documentDescription)) {
            if (LVRequired) {
                return true;
            }
        }

        if (['On-Site Transfer Form'].includes(document.documentDescription)) {
            if (transferFormRequired) {
                return true;
            }
        }

        if (['Resident Screening Criteria', 'Deposit and Fee Structure'].includes(document.documentDescription)) {
            if (criteriaRequired) {
                return true;
            }
        }

        if (['Roommate Addendum'].includes(document.documentDescription)) {
            if (rmAddendumRequired) {
                return true;
            }
        }

        return document.isRequired;
    }


    return (
        <QuestionWrapper>
            <Label to='documentSelect'>{props.RequestTypeDetail.questionLabel}</Label>
            <LightBox id='documentSelect'>
                <h6>Please provide the documents listed. Documents with a {<Required />} are required to submit the form. If you have more documents than there is room for here, you will generally be able add additional documents after submitting the form. Maximum document size: 45 MB.</h6>

                <QuestionWrapper>
                    <div className={styles.header}>Document Name</div>
                    <div className={styles.header}>Uploaded Filename</div>
                </QuestionWrapper>

                {props.RequestTypeDetail.requestTypeDetailAssociatedDocuments.filter(x => x.isActive).sort((a, b) => a.sortOrder - b.sortOrder < 0 ? -1 : 1).map(d => {
                    return (
                        <RequestDetail key={d.id} RequestTypeDetail={
                            {
                                id: 'requestTypeAssociatedDocument',
                                isRequired: documentIsRequired(d),
                                questionLabel: d.documentDescription,
                                linkUrl: d.linkUrl
                            }}>
                            <Input className="requestTypeAssociatedDocument" type="file" id={"fileUpload_" + d.id} />
                            {(d.documentDescription.toLowerCase() === "Certificate of Insurance".toLowerCase() || d.documentDescription.includes("COI")) &&
                                <>
                                    <div id={"tooltip_" + d.id} className={styles.tooltipIdentifier} onClick={() => setShowPopover(!showPopover)}>COI Requirements</div>
                                    <UncontrolledPopover
                                        className='documentPopover'
                                        isOpen={showPopover}
                                        trigger="legacy"
                                        toggle={() => setShowPopover(!showPopover)}
                                        target={"tooltip_" + d.id}
                                        placement="right">
                                        <PopoverHeader className={styles.popoverHeader}>COI Requirements</PopoverHeader>
                                        <PopoverBody className={styles.popoverBody}>
                                            All COI's need the following: In addition to being a certificate holder, Monarch must be named additionally insured for General Liability as well as adding Monarch and address or property as a certificate holder.<br /><br />
                                            All COI's need to have the below information included in the Additional Remarks: <br /><br />
                                            "Monarch Investment and Management Group, LLC and [Property Legal name] are included as additional insured with respect to the foregoing Commercial General Liability Policy, and coverage is Primary and Non-contributory."
                                        </PopoverBody>
                                    </UncontrolledPopover>
                                </>
                            }
                        </RequestDetail>
                    )
                })}
            </LightBox>
        </QuestionWrapper>
    );
}