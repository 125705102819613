/* eslint-disable no-loop-func */
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { axios, Button, ErrorLogModel, getNowMountainTime, logError } from 'react-mimg';
import { Spinner } from 'reactstrap';
import validator from 'validator';
import { getNextStepInfo } from '../../../functions/formHelpers/genericRequestFormHelper';
import { CustomAlertModel } from '../../../models/CustomAlertModel';
import { FileModel } from '../../../models/FileModel';
import { GenericRequestFormDetailsModel } from '../../../models/genericrequestforms/GenericRequestFormDetailsModel';
import { GenericRequestFormModel } from '../../../models/genericrequestforms/GenericRequestFormModel';
import { GenericRequestFormPropertiesModel } from '../../../models/genericrequestforms/GenericRequestFormPropertiesModel';
import { checkIfFileExtensionIsValid } from '../../../functions/validation';
import { uploadImage } from '../actionbuttons/UploadImage';

var CryptoJS = require("crypto-js");

export default function RequestSubmitButton(props) {
    const [allowSubmit, setAllowSubmit] = useState(true);

    async function onSave() {
        let pageAlert = new CustomAlertModel();
        let genericRequestFormModel = new GenericRequestFormModel();
        let model = new GenericRequestFormModel();
        let requestTypeId = parseInt(document.getElementById("selectRequestType").selectedOptions[0].id);
        let requestType = props.requestTypes.filter(x => x.id === requestTypeId)[0];
        let workflow = requestType.approvalWorkflowNavigation;
        let fileTime = Date.now();

        let validationMsg = await getSubmitFormValidationMsg(requestType);
        if (validationMsg === "") {
            setAllowSubmit(false)
            genericRequestFormModel = await populateGenericRequestFormModel(requestType, workflow);

            let currentStepId = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Submit')[0].id;
            let nextStepId = getNextStepInfo(workflow, currentStepId);
            genericRequestFormModel.currentWorkflowStep = nextStepId
            let nextStepName = workflow.requestWorkflowSteps.filter(x => x.id === nextStepId)[0]?.stepName;
            genericRequestFormModel.requestStatus = nextStepName;

            let genericRequestProperties = [];
            if (props.requestProperties) {
                genericRequestProperties = props.requestProperties.map(prop => {
                    let aptProperty = new GenericRequestFormPropertiesModel();
                    aptProperty.siteId = prop.siteId;
                    return aptProperty;
                })
            }

            if (props.formType !== "licensetooccupy") {
                var alternatePropertySelectionInputs = document.querySelectorAll('input[name="propertyPicker"]');
                if (alternatePropertySelectionInputs.length > 0)
                    genericRequestProperties = []; //the properties are not selected at the top of the form, but that control is just hidden and the default value from it was populating as a Request Form Property which we don't want

                alternatePropertySelectionInputs.forEach(input => {
                    let aptProperty = new GenericRequestFormPropertiesModel();
                    aptProperty.siteId = input.value;
                    genericRequestProperties.push(aptProperty);
                })
            }
            genericRequestFormModel.genericRequestFormProperties = genericRequestProperties;

            var genericDetailHolders = Array.from(document.getElementsByClassName("genericDetailHolderDiv"));

            var couldHaveDocuments;
            let genericRequestDetails = [];
            for (let i = 0; i < genericDetailHolders.length; i++) {
                let div = genericDetailHolders[i];
                var isDoc = div.querySelector('.requestTypeAssociatedDocument');
                if (!isDoc) { //documents are not stored in the GenericRequestFormDetails table, they are saved to the GenericRequestFormDocuments table and treated as documents after the form is submitted
                    var detailModel = new GenericRequestFormDetailsModel();
                    let inputElement = div.querySelector('.genericRequestDetailInput').children[0];
                    detailModel.isEncrypted = div.getAttribute("isencrypted") === "Yes" ? true : false;

                    detailModel.detailId = parseInt(div.id);

                    if (inputElement.nodeName === "SELECT") {
                        let sep = "";
                        for (let i = 0; i < inputElement.selectedOptions.length; i++) {
                            detailModel.response = `${detailModel.response}${sep}${inputElement.selectedOptions[i].value}`;
                            sep = "; ";
                        }
                    }
                    else if (inputElement.nodeName === "INPUT" || inputElement.nodeName === "TEXTAREA") {
                        if (inputElement.type === 'date')
                            detailModel.response = new Date(inputElement.value + 'T00:00:00').toLocaleDateString();
                        else
                            detailModel.response = inputElement.value.replace(/\n/g, "; ");
                    }
                    else if (inputElement.textContent) { //react-select 
                        let inputsWitReactDefault = inputElement.querySelectorAll('input');
                        let selections = Array.from(inputsWitReactDefault).slice(1);
                        detailModel.response = '';
                        for (let i = 0; i < selections.length; i++) {
                            if (selections[i].value) {
                                detailModel.response += inputElement.children[2].children[0].children[i].textContent + ';'
                            }
                        }
                        if (detailModel.response.length > 0) {
                            detailModel.response = detailModel.response.slice(0, -1)
                        }

                        var employeePickerInputs = inputElement.querySelectorAll('input[name="employeePicker"]');
                        for (let j = 0; j < employeePickerInputs.length; j++) {
                            let input = employeePickerInputs[j]
                            if (input.value) {
                                genericRequestFormModel.genericRequestFormEmployees.push(
                                    {
                                        propertyEmployeesId: 0, //this is set at the server, since we need to determine if the input.value is an integer (known property employees id, or just a string (the person's name)'))
                                        employeeName: input.value,
                                        formId: 0,
                                        sortOrder: (i * 10) + j, //i is the order of all details in the form, and j is the order of all employees within the individual employee picker component, but there can be multiple employees in any employee picker.  So if it is the first detail, we will get a sort order of 0 + the number of employees in the picker for each detail.  If there are two employees in a form, they will likely have sort orders like 10 and 90. 
                                        detailId: detailModel?.detailId,
                                        questionLabel: div?.querySelector(".genericRequestDetailQuestionLabel")?.innerText
                                    })
                            }
                        }
                    }
                    detailModel = encryptDetailModelIfNeeded(detailModel);

                    genericRequestDetails.push(detailModel);
                } else {
                    couldHaveDocuments = true; //should already be validated as a real doc by this stage
                }
            }

            genericRequestFormModel.genericRequestFormDetails = genericRequestDetails.filter(x => x !== undefined);

            if (requestType.automaticallyAssignToEmpId && requestType.automaticallyAssignToEmpId > 0) { //this is specified on the Request Type maintenance page, and only available if the request workflow has an associated step with button name "Assign"
                genericRequestFormModel.assignedToEmpId = requestType.automaticallyAssignToEmpId;
                genericRequestFormModel.assignedToName = requestType.automaticallyAssignToEmpName;
                genericRequestFormModel.assignedToDateTime = getNowMountainTime();
                genericRequestFormModel.assignedToByEmpId = 0;
                genericRequestFormModel.assignedToByName = 'Automatically Assigned On Request Submission';
                genericRequestFormModel.assignedToEmail = requestType.automaticallyAssignToEmpEmail;

                let currentStep = workflow.requestWorkflowSteps.filter(x => x.associatedButtonName === 'Assign')?.sort((a, b) => { return a.sortOrder - b.sortOrder })[0];
                genericRequestFormModel.currentWorkflowStep = getNextStepInfo(workflow, currentStep.id);

                genericRequestFormModel.requestStatus = "Assigned"; //not entirely 100% sure this will work, but requests "Assigned" from the Assign Modal also have this status
            }

            let files = [];
            let documentModels = [];
            let index = 0;
            axios.post("api/GenericRequestForm/SaveNewRequest", genericRequestFormModel).then((response) => {
                if (response.data.data != null) {
                    genericRequestFormModel = response.data.data;
                    model = genericRequestFormModel;
                    pageAlert.AlertText = "The request was submitted successfully";
                    pageAlert.AlertColor = "success";

                    //check if we need to add some documents due to having a RequestDetailAssociatedDocuments component
                    if (couldHaveDocuments) {
                        genericDetailHolders.forEach(div => {
                            var fileUpload = div.querySelector('.requestTypeAssociatedDocument');
                            if (fileUpload) {
                                if (fileUpload.value !== "") {
                                    index += 1;
                                    //populate document info before uploading them in the finally clause, we can update the main page before the documents go to upload if there is nothing being awaited here
                                    let questionLabel = div.querySelector(".genericRequestDetailQuestionLabel").innerText.replace(' *', '').replace('*', '');

                                    let fileName = getFixedFileName(fileUpload.files[0], fileTime, genericRequestFormModel, index);
                                    let file = new FileModel();
                                    file.file = fileUpload.files[0];
                                    file.subFolderPath = props.formType;
                                    file.fileName = fileName;
                                    files.push(file);

                                    let documentModel = populateDocumentModel(genericRequestFormModel, questionLabel, file, fileUpload.files[0].name);
                                    documentModels.push(documentModel);

                                    pageAlert.AlertText = pageAlert.AlertText + " and the documents are in the process of being uploaded;";
                                }
                            }
                        })
                    }
                } else {
                    pageAlert.AlertColor = "danger";
                    pageAlert.AlertText = response.data.errorMessage;
                    props.showValidationError(pageAlert);
                }
            }).catch((error) => {
                logError(new ErrorLogModel(error, "OnSubmitButtonClick", props.security.employeeFullName + " experienced an error adding a new GenericRequestForm (CorporateServices)"));
                pageAlert.AlertColor = "danger";
                pageAlert.AlertText = "Error creating new form. The form was not saved successfully.";
                setAllowSubmit(true)
                props.showValidationError(pageAlert);
            }).finally(async () => {
                let getExistingRequestUrl = "api/GenericRequestForm/GetRequestByIdAndType?Id=" + genericRequestFormModel.id + "&formName=" + props.formType;
                if (files.length > 0 && (files.length === documentModels.length)) { //we want to upload the files
                    await uploadAllFiles(files, documentModels, model);

                    axios.get(getExistingRequestUrl).then((response) => { //reload the data so that the attachments we just added have ids, this was originally supposed to be returned from the uploadAllFiles sub, but didn't work with the threading very well
                        genericRequestFormModel = response.data;

                        if (genericRequestFormModel.genericRequestFormDocuments.length > 0) {
                            pageAlert.AlertText = "The request was submitted successfully and the requested documents were also uploaded.";
                            props.onClick(genericRequestFormModel, pageAlert, "Submit");
                        } else {
                            pageAlert.AlertText = "The request was entered into the system correctly, but there was an issue saving the documents you added. Make sure your documents are no more than 45MB in size. Please use the 'Add Attachment' button at the bottom of the page to submit the documents again";
                            props.onClick(genericRequestFormModel, pageAlert, "Submit");
                        }
                    })
                } else {
                    axios.get(getExistingRequestUrl).then((response) => {
                        genericRequestFormModel = response.data;
                        pageAlert.AlertText = "The request was submitted successfully and you may now add documents if you would like.";
                        props.onClick(genericRequestFormModel, pageAlert, "Submit");
                    })
                }
            });

        } else {

            pageAlert.AlertColor = "danger";
            pageAlert.AlertText = validationMsg;
            setAllowSubmit(true)
            props.showValidationError(pageAlert);
        }
    }

    async function populateGenericRequestFormModel(requestType, workflow) {
        let genericRequestFormModel = new GenericRequestFormModel();
        genericRequestFormModel.relatedToFormName = requestType.relatedToFormName;
        if (props.actuallyForEmpCard) { //there is an admin putting in the request on someone else's behalf
            genericRequestFormModel.requesterEmpId = parseInt(props.actuallyForEmpCard.propertyEmployeesId);
            genericRequestFormModel.requesterName = props.actuallyForEmpCard.employeeName;
            genericRequestFormModel.requesterSiteId = parseInt(props.actuallyForEmpCard.siteId);
            genericRequestFormModel.requesterPropertyName = props.actuallyForEmpCard.homeProperty;
            genericRequestFormModel.requesterEmail = props.actuallyForEmpCard.email;
            genericRequestFormModel.requesterPhone = props.actuallyForEmpCard.phone;
            genericRequestFormModel.requesterPosition = props.security.position;
        } else { //the person using the website is the person that the form is for
            genericRequestFormModel.requesterEmpId = parseInt(props.security.empID);
            genericRequestFormModel.requesterName = props.security.employeeFullName;
            genericRequestFormModel.requesterSiteId = parseInt(props.security.employeeSiteID);
            genericRequestFormModel.requesterPropertyName = props.security.employeePropertyNamePerAptProperties; //this is pulling position for some reason now,needs fixed
            genericRequestFormModel.requesterEmail = props.security.employeeEmail;
            genericRequestFormModel.requesterPhone = props.employeePhone ? props.employeePhone : props.security.employeePhone;
            genericRequestFormModel.requesterPosition = props.security.employeePosition;

        }
        genericRequestFormModel.requestTypeId = requestType.id;
        genericRequestFormModel.requestStatus = workflow.requestWorkflowSteps[0].stepName;
        genericRequestFormModel.additionalDetails = await transformAdditionalDetails(genericRequestFormModel.id);
        genericRequestFormModel.createDateTime = moment.tz("America/Denver").local().format('YYYY-MM-DD h:mm:ss A');
        genericRequestFormModel.workflowId = workflow.id;
        genericRequestFormModel.requestComplete = false;
        genericRequestFormModel.requestRejected = false;

        return genericRequestFormModel;
    }

    async function transformAdditionalDetails(formId) {
        if (props.formPreferences?.allowRichTextAdditionalDetails) {
            let value = props.quillRef.current.root.innerHTML;
            value = value.replaceAll('<p><br></p>', '<br />')
            const imageRegex = /<img[^>]+src="([^">]+)"/g;
            const images = [];
            let match;
            while ((match = imageRegex.exec(value)) !== null) {
                images.push(match[1]);
            }

            for (let i = 0; i < images.length; i++) {
                const imageUrl = images[i];
                const response = await uploadImage(imageUrl, formId);
                value = value.replace(imageUrl, response.data.url);
            }

            return value;
        } else {
            return document.getElementById("txtOtherRequestInfo").value.replace(/\n/g, "; ")
        }
    }

    function populateDocumentModel(genericRequestFormModel, questionLabel, file, originalFileName) {
        let documentModel = {};
        documentModel.docType = props.formType;
        documentModel.docDescription = questionLabel;
        documentModel.fileDirectory = props.formType;
        documentModel.docDate = getNowMountainTime();
        documentModel.createDateTime = getNowMountainTime();
        documentModel.fileName = file.fileName;
        if (props.actuallyForEmpCard) {
            documentModel.uploadedByEmpID = props.security.empID;
            documentModel.uploadedByName = props.security.employeeFullName;
            documentModel.isByRequester = false;
        } else {
            documentModel.uploadedByEmpID = props.security.empID;
            documentModel.uploadedByName = props.security.employeeFullName;
            documentModel.isByRequester = (parseInt(props.security.empID) === genericRequestFormModel.requesterEmpId);
        }

        documentModel.originalFileName = originalFileName;

        return documentModel;
    }

    function encryptDetailModelIfNeeded(detailModel) {
        if (detailModel.isEncrypted) {
            let key = process.env.REACT_APP_GENERIC_REQUESTS_CLIENT_SIDE_KEY + detailModel.detailId;

            let encrypted = CryptoJS.AES.encrypt(detailModel.response, key).toString();
            detailModel.response = encrypted;
        }

        return detailModel;
    }


    async function getSubmitFormValidationMsg(requestType) {
        let msg = "";
        let genericDetailHolders = Array.from(document.getElementsByClassName("genericDetailHolderDiv"));

        if (genericDetailHolders.length === 0 || requestType.requireAdditionalDetails) { //now the additional details are required
            let additionalDetails = props.formPreferences.allowRichTextAdditionalDetails ? props.quillRef.current.root.innerHTML : document.getElementById("txtOtherRequestInfo").value;
            if (!validator.isLength(additionalDetails, { min: 10 }) || additionalDetails === '<p><br></p>') {
                msg += "The additional details are required for this request type and needs to be at least 10 characters long.";
            }
        } else {
            genericDetailHolders.forEach(div => {
                let question = div.querySelector(".genericRequestDetailQuestionLabel").innerText.replace(' *', '').replace('*', '');
                let inputElement = div.querySelector('.genericRequestDetailInput').children[0];
                if (div.querySelector(".mimgRequiredInput") !== null) {
                    let response = '';
                    if (inputElement.nodeName === "SELECT") {
                        let sep = "";
                        for (let i = 0; i < inputElement.selectedOptions.length; i++) {
                            response = `${response}${sep}${inputElement.selectedOptions[i].value}`;
                            sep = "; ";
                        }
                    }
                    else if (inputElement.nodeName === "INPUT" || inputElement.nodeName === "TEXTAREA") {
                        response = inputElement.value;
                    }
                    else {
                        response = inputElement.textContent
                    }

                    if (!validator.isLength(response, { min: 0, max: 4000 })) {
                        msg += "Response to " + question + "should be less than 4000 characters";
                    }

                    if (!response || response === "No Selection" || response === "Select...") {
                        if (msg === "") {
                            msg += "You must answer the following required questions or provide the following documents in order to submit the form:\n";
                        }
                        if (inputElement.type === "file") {
                            msg += "Missing Required Document: " + question + "\n";
                        } else {
                            if (validator.isLength(response, { min: 0, max: 1000 })) {
                                msg += 'The response to the question "' + question + '" is required.\n';
                            } else {
                                msg += question + "\n";
                            }
                        }
                    }
                }
                let validationType = div.getAttribute("validationtype") ? div.getAttribute("validationtype") : "none";
                if (validationType !== "none" && inputElement) {
                    msg += getDefinedTypeValidationMsg(question, inputElement.value, validationType);
                }
            })
        }

        //check file extensions
        await genericDetailHolders.map(async (div) => {
            let questionLabel = div.querySelector(".genericRequestDetailQuestionLabel").innerText.replace(' *', '').replace('*', '');
            let response = div.querySelector('.genericRequestDetailInput');
            if (response.type === "file" && response.value !== null & response.value !== undefined && response.value !== "") {
                let isValidMsg = await checkIfFileExtensionIsValid(response.files[0]);
                if (isValidMsg !== "") {
                    msg += `The file entered for ${questionLabel} is invalid. ${isValidMsg}`;
                }
            }
        })

        return msg;
    }

    function getDefinedTypeValidationMsg(question, response, validationType) {
        let typeName = "";
        let msg = "";
        switch (validationType.toLowerCase()) {
            case "email":
                if (!validator.isEmail(response)) {
                    typeName = "email address";
                }
                break;
            case "monarch email":
                if (!validator.isEmail(response) || !response.toLowerCase().includes('@monarchinvestment.com')) {
                    typeName = "email address ending in @monarchinvestment.com";
                }
                break;
            case "date":
                if (!validator.isDate(response)) {
                    typeName = "date";
                }
                break;

            case "integer": //decided not to distinguish positive/negative, can't think of any examples of needing negatives here
                if (!validator.isInt(response, { min: 1 }))
                    typeName = "integer greater than zero"
                break;
            case "phone":
                if (!validator.isMobilePhone(response))
                    typeName = "phone number"
                break;
            case "zipcode":
                let regex = new RegExp("^[0-9]{5}(?:-[0-9]{4})?$");
                if (!regex.test(response))
                    typeName = "zip code"
                break;

            default:
                break;
        }

        if (typeName !== "") { //type name is only going to be set if there is a problem
            msg = `The response to the question "${question}" should be a valid ${typeName}.\n`;
        } else {
            msg = "";
        }
        return msg;

    }

    function getFixedFileName(file, fileTime, genericRequestFormModel, index) {
        let ext = file.name.split('.').pop();
        let formId = genericRequestFormModel.id.toString();
        let sixDigitFormId = formId.padStart(6, '0');

        return `grf${sixDigitFormId}-num${index}.${ext}`;
    }


    async function uploadAllFiles(files, documentModels, genericRequestFormModel) {
        var index = 0;
        for (const file of files) {
            let documentModel = documentModels[index];
            index += 1;

            let formData = new FormData();

            formData.append("formFile", file.file);
            formData.append("fileName", documentModel.fileName);
            formData.append("subFolderName", file.subFolderPath)
            formData.append("formId", genericRequestFormModel.id);

            await axios.post("api/file/PostEncryptedFile", formData)
                .then(async () => {
                    genericRequestFormModel.genericRequestFormDocuments.push(documentModel);
                    if (index === files?.length) {
                        await axios.put("api/GenericRequestForm/UpdateRequest?updateAction=addDocumentSubmit", genericRequestFormModel).then((response) => {
                            //var fileName = response.data.genericRequestFormDocuments[0].originalFileName;
                            for (let i = 0; i < response.data.genericRequestFormDocuments?.length; i++) {
                                let docInfo = response.data.genericRequestFormDocuments[i];
                                axios.post(`api/file/AddDocumentIdToBlob?fileName=${docInfo.fileName}&formId=${docInfo.formId}&docId=${docInfo.id}`);
                            }
                        })
                    }

                    await new Promise(r => setTimeout(r, 2000)); //loop above was not always finishing before leaving here, timeout should give a little extra time. added email to self on failure incase that is not acutally the problem

                }).catch(error => {
                    logError(new ErrorLogModel(error, "RequestSubmitButton/UploadFile", "Error adding file to request. Filename: " + file.fileName + ", size: " + Math.round(file.file.size / 1024) + "KB"));
                })
        }
    }

    return (
        <>
            <Button onClick={onSave} disabled={!allowSubmit}>
                {allowSubmit
                    ? <span>Submit</span>
                    : <Spinner>Loading...</Spinner>
                }

            </Button>
        </>
    );
}

