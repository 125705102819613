import React, { useState } from 'react';
import { Input, Modal, ModalHeader, ModalBody, ModalFooter, Alert, Form, FormGroup } from 'reactstrap';
import { Button, axios } from 'react-mimg';
import { isEmailValid } from '../../../functions/validation';
import { createComment } from '../../../functions/formHelpers/genericRequestFormHelper';
import { getEmployeeSecurity } from '../../../functions/permissions';

export default function ChangeEmailModal(props) {
    const security = getEmployeeSecurity();
    const originalEmail = props.genericRequestFormModel.genericRequestFormDetails.find(x => x.detailId === 409)?.response;
    const [alert, setAlert] = useState({});

    function updateEmail(e) {
        e.preventDefault();
        setAlert({})
        if (isEmailValid(e.target.email.value)) {
            let newEmail = e.target.email.value;
            let genericRequestFormCopy = JSON.parse(JSON.stringify(props.genericRequestFormModel));
            let commentText = `Employee email changed from ${originalEmail} to ${newEmail}`;
            genericRequestFormCopy.genericRequestFormDetails.find(x => x.detailId === 409).response = newEmail;
            genericRequestFormCopy.genericRequestFormComments.push(createComment(security.empID, commentText))
            axios.put('api/GenericRequestForm/UpdateRequestProperties', genericRequestFormCopy).then((response) => {
                window.location = `requests/${props.genericRequestFormModel.relatedToFormName}/${props.genericRequestFormModel.id}?justChanged=1`;
            })
            props.toggle()
        } else {
            setAlert({ color: "danger", messages: "Can't update. Not a valid email"})
        }
    }

    return (
        <div>
            <Modal size="md" isOpen={props.isOpen} toggle={props.toggle}>
                <ModalHeader toggle={props.toggle}>Change Employee Email</ModalHeader>
                <Form onSubmit={updateEmail}>
                    <ModalBody>
                        {alert && alert.messages?.length > 0 &&
                            <Alert toggle={() => setAlert({})} color={alert.color}>{alert.messages }</Alert>
                        }
                        <FormGroup>
                            <Input required name="email" placeholder="type new email address" />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <FormGroup>
                            <Button transparent onClick={props.toggle}>Cancel</Button>
                            <Button type="submit">Update Employee Email</Button>
                        </FormGroup>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>

    );
}
