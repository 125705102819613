import React, { useState, useEffect, useRef } from 'react';
import { Label, Input, Alert } from 'reactstrap';
import RequestDetailCheckbox from '../requestdetails/RequestDetailCheckbox';
import RequestDetailDropdown from '../requestdetails/RequestDetailDropdown';
import RequestDetailListbox from '../requestdetails/RequestDetailListbox';
import RequestDetailShortText from '../requestdetails/RequestDetailShortText';
import RequestDetailTextArea from '../requestdetails/RequestDetailTextArea';
import RequestDetailPropertySelect from '../requestdetails/RequestDetailPropertySelect';
import RequestDetailPropertySelectYardi from '../requestdetails/RequestDetailPropertySelectYardi';
import RequestDetailAssociatedDocuments from '../requestdetails/RequestDetailAssociatedDocuments';
import RequestDetailDatePicker from '../requestdetails/RequestDetailDatePicker';
import flexStyles from '../../../styles/flex.module.scss';
import { RequestDetailStatesList } from '../requestdetails/RequestDetailStatesList';
import RequestDetailNumber from '../requestdetails/RequestDetailNumber';
import RequestDetailEmployeeForSitePicker from '../requestdetails/RequestDetailEmployeeForSitePicker';
import RequestDetailMultiEmployeeForSitePicker from '../requestdetails/RequestDetailMultiEmployeeForSitePicker';
import RequestDetailJobTitlePicker from '../requestdetails/RequestDetailJobTitlePicker';
import RequestDetailJobDisplayNamePicker from '../requestdetails/RequestDetailJobDisplayNamePicker';
import RequestDetailEmployeeNameFromId from '../requestdetails/RequestDetailEmployeeNameFromId';
import QuestionWrapper from './QuestionWrapper';
import Required from '../../layout/Required';
import RequestDetailEmployeePositionGroupPicker from '../requestdetails/RequestDetailEmployeePositionGroupPicker';
import RequestDetailEmailGroupPicker from '../requestdetails/RequestDetailEmailGroupPicker';
import RequestDetailUnitAvailability from '../requestdetails/RequestDetailUnitAvailability';
import RequestRichInput from '../actionbuttons/RequestRichInput';
import { useQuery } from '../../../functions/routing';
import RequestDetailMyEmployeePicker from '../requestdetails/RequestDetailMyEmployeePicker';
import RequestDetailKey1FromQuery from '../requestdetails/RequestDetailKey1FromQuery';

export default function RequestDetailsBlank(props) {

    const [selectedRequestType, setSelectedRequestType] = useState();
    const [propertyEmployeesId, setPropertyEmployeesId] = useState(0);
    const quillRef = useRef();
    const query = useQuery();

    useEffect(() => {
        let requestType;
        let queryString = query.get('requesttype')
        if (props.selectedRequestTypeId > 0) {
            requestType = props.requestTypes.filter(x => x.id === props.selectedRequestTypeId)[0];
        } else if (parseInt(queryString) > 0) {
            requestType = props.requestTypes.filter(x => x.id === parseInt(queryString))[0];
        } else {
            if (props.formPreferences?.requireRequestTypeSelection) {
                //user has to select something, otherwise there is no submit button
            } else {
                requestType = props.requestTypes[0]
            }

        }

        setSelectedRequestType(requestType);
        props.onRequestTypeChange && props.onRequestTypeChange(requestType);
    }, [props.selectedRequestTypeId])

    useEffect(() => {
        setPropertyEmployeesId(getPropertyEmployeesId());
    }, [props.propertyEmployeesId, props.defaultValues])

    function onRequestTypeChange(e) {
        var dropDown = e.target;
        var selectedId = parseInt(dropDown.selectedOptions[0].id);
        let requestType = props.requestTypes.find(x => x.id === selectedId);
        setSelectedRequestType(requestType)
        props.onRequestTypeChange && props.onRequestTypeChange(requestType);
    }

    function getPropertyEmployeesId() {
        if (props.propertyEmployeesId)
            return props.propertyEmployeesId;

        if (!props.defaultValues)
            return 0;

        let findVal = props.defaultValues.find(x => x.defaultDataPropName === "propertyEmployeesId")?.value
        return findVal;
    }

    return (
        <div>
            {!selectedRequestType &&
                <Alert color="warning">Please select a request type from the list below to show the questions necessary to submit the rest of the form!</Alert>
            }
            <QuestionWrapper>
                <Label for="selectRequestType">Select a Request Type</Label>
                <Input type="select" id="selectRequestType" value={selectedRequestType?.id} onChange={onRequestTypeChange} disabled={props.requestTypes.length + 1 === 1}>
                    {props.formPreferences?.requireRequestTypeSelection && (!props.selectedRequestTypeId || props.selectedRequestTypeId === 0) &&
                        <option id="0" value={0}>--Select A Request Type To Continue--</option>
                    }

                    {props.requestTypes &&
                        props.requestTypes.map(p => {
                            return <option id={p.id} key={p.id} value={p.id}>{p.requestType1}</option>
                        })}
                </Input>
            </QuestionWrapper>
            {selectedRequestType?.instructions &&
                <div className={flexStyles.rowFlexContainer80}>
                    <Alert dismissable="true" id="alert" className="alert" isOpen={true} color="primary">
                        <span dangerouslySetInnerHTML={{ __html: selectedRequestType?.instructions }}></span>
                    </Alert>
                </div>
            }


            {selectedRequestType?.id > 0 &&
                selectedRequestType.requestTypeDetails.map(p => {
                    //if there is an object passed in with default values, populate them.
                    let defaultValues = props.defaultValueList;
                    let defaultValue;
                    if (props.defaultValueList && p.defaultDataPropName) {
                        defaultValue = defaultValues.find(x => x.defaultDataPropName === p.defaultDataPropName)?.value;
                    }

                    return <div key={p.id}>
                        {p.responseControlType === "RequestDetailShortText" &&
                            <RequestDetailShortText RequestTypeDetail={p} defaultValue={defaultValue} />}
                        {p.responseControlType === "RequestDetailNumber" &&
                            <RequestDetailNumber RequestTypeDetail={p} defaultValue={defaultValue} />}
                        {p.responseControlType === "RequestDetailDropdown" &&
                            <RequestDetailDropdown RequestTypeDetail={p} />}
                        {p.responseControlType === "RequestDetailTextArea" &&
                            <RequestDetailTextArea RequestTypeDetail={p} defaultValue={defaultValue} />}
                        {p.responseControlType === "RequestDetailListbox" &&
                            <RequestDetailListbox RequestTypeDetail={p} />}
                        {p.responseControlType === "RequestDetailCheckbox" &&
                            <RequestDetailCheckbox RequestTypeDetail={p} />}
                        {p.responseControlType === "RequestDetailStatesList" &&
                            <RequestDetailStatesList RequestTypeDetail={p} defaultValue={defaultValue} />}
                        {p.responseControlType === "RequestDetailAssociatedDocuments" &&
                            <RequestDetailAssociatedDocuments RequestTypeDetail={p} />}
                        {p.responseControlType === "RequestDetailDatePicker" &&
                            <RequestDetailDatePicker RequestTypeDetail={p} />}
                        {p.responseControlType === "RequestDetailEmployeeForSitePicker" &&
                            <RequestDetailEmployeeForSitePicker
                                selectedProperties={props.selectedProperties}
                                RequestTypeDetail={p}
                                isAdmin={props.isAdmin}
                                onSelectEmployee={props.onSelectEmployee} />}
                        {p.responseControlType === "RequestDetailEmployeeForSitePicker2" &&
                            <RequestDetailEmployeeForSitePicker
                                selectedProperties={props.selectedProperties}
                                RequestTypeDetail={p}
                                isAdmin={props.isAdmin}
                                onSelectEmployee={props.onSelectEmployee2} />}

                        {p.responseControlType === "RequestDetailMultiEmployeeForSitePicker" &&
                            <RequestDetailMultiEmployeeForSitePicker
                                selectedProperties={props.selectedProperties}
                                RequestTypeDetail={p}
                                isAdmin={props.isAdmin} />}
                        {p.responseControlType === "RequestDetailMyEmployeePicker" &&
                            <RequestDetailMyEmployeePicker
                                RequestTypeDetail={p}
                                isAdmin={props.isAdmin}
                                onSelectEmployee={props.onSelectEmployee}
                                myEmployees={props.myEmployees} />}
                        {p.responseControlType === "RequestDetailJobTitlePicker" &&
                            <RequestDetailJobTitlePicker
                                selectedProperties={props.selectedProperties}
                                RequestTypeDetail={p}
                                isAdmin={props.isAdmin} />}
                        {p.responseControlType === "RequestDetailJobDisplayNamePicker" &&
                            <RequestDetailJobDisplayNamePicker
                                selectedProperties={props.selectedProperties}
                                RequestTypeDetail={p}
                                isAdmin={props.isAdmin} />}
                        {p.responseControlType === "RequestDetailEmployeePositionGroupPicker" &&
                            <RequestDetailEmployeePositionGroupPicker
                                RequestTypeDetail={p} />}
                        {p.responseControlType === "RequestDetailEmployeeNameFromId" &&
                            <RequestDetailEmployeeNameFromId RequestTypeDetail={p}
                                propertyEmployeesId={props.propertyEmployeesId}
                                selectedProperties={props.selectedProperties}
                                isAdmin={props.isAdmin}
                                onSelectEmployee={props.onSelectEmployee}
                                defaultToComponent="RequestDetailEmployeeForSitePicker"
                                propertyList={props.propertyList}
                                onUpdateSelectedProperties={props.onUpdateSelectedProperties} />
                        }

                        {p.responseControlType === "RequestDetailEmailGroupPicker" &&
                            <RequestDetailEmailGroupPicker RequestTypeDetail={p}
                                propertyEmployeesId={propertyEmployeesId} />
                        }
                        {p.responseControlType === "RequestDetailPropertySelect" &&
                            <RequestDetailPropertySelect RequestTypeDetail={p}
                                propertyList={props.propertyList}
                                propertyEmployeesId={propertyEmployeesId}
                                onUpdateSelectedProperties={props.onUpdateSelectedProperties}
                                allowAllPropertiesOption={true}
                                hideRequestPropertySelection={props.formPreferences?.hideRequestPropertySelection}
                                setDefaultProperties={true}
                                isMulti={true}
                                formPreferences={props.formPreferences}
                            />
                        }

                        {p.responseControlType === "RequestDetailPropertySelectWithNoSelectAllOption" &&
                            <RequestDetailPropertySelect RequestTypeDetail={p}
                                propertyList={props.propertyList}
                                propertyEmployeesId={propertyEmployeesId}
                                onUpdateSelectedProperties={props.onUpdateSelectedProperties}
                                allowAllPropertiesOption={false}
                                hideRequestPropertySelection={props.formPreferences?.hideRequestPropertySelection}
                                setDefaultProperties={true}
                                isMulti={true}
                                formPreferences={props.formPreferences}
                            />
                        }

                        {p.responseControlType === "RequestDetailPropertySelectSingle" &&
                            <RequestDetailPropertySelect RequestTypeDetail={p}
                                propertyList={props.propertyList}
                                propertyEmployeesId={propertyEmployeesId}
                                onUpdateSelectedProperties={props.onUpdateSelectedProperties}
                                allowAllPropertiesOption={false}
                                hideRequestPropertySelection={props.formPreferences?.hideRequestPropertySelection}
                                setDefaultProperties={false}
                                isMulti={false}
                                formPreferences={props.formPreferences}
                            />
                        }

                        {p.responseControlType === "RequestDetailPropertySelectYardi" &&
                            <RequestDetailPropertySelectYardi RequestTypeDetail={p}
                                propertyList={props.propertyList}
                                propertyEmployeesId={propertyEmployeesId}
                                onUpdateSelectedProperties={props.onUpdateSelectedProperties}
                                allowAllPropertiesOption={false}
                                hideRequestPropertySelection={props.formPreferences?.hideRequestPropertySelection}
                                formPreferences={props.formPreferences}
                            />
                        }

                        {p.responseControlType === "RequestDetailUnitAvailability" &&
                            <RequestDetailUnitAvailability RequestTypeDetail={p}
                                selectedProperties={props.selectedProperties} />

                        }
                        {p.responseControlType === "RequestDetailKey1FromQuery" &&
                            <RequestDetailKey1FromQuery RequestTypeDetail={p} requestDetailKey1={props.requestDetailKey1} />}

                    </div>
                })
            }
            {selectedRequestType && !selectedRequestType?.instructionsOnlyRequestType &&
                <QuestionWrapper>
                    <Label to='txtOtherRequestInfo'>
                        Additional Information or Details
                        {(selectedRequestType?.requestTypeDetails.length === 0 || selectedRequestType.requireAdditionalDetails) && <Required />}
                        {props.formPreferences.allowRichTextAdditionalDetails &&
                            <><br /><br />You may copy/paste images or screenshots, or drag an image file directly into this input ---> </>
                        }
                    </Label>
                    {props.formPreferences.allowRichTextAdditionalDetails
                        ? <RequestRichInput r={props.quillRef} hideBlurb={true} />
                        : <Input
                            id='txtOtherRequestInfo'
                            rows='4'
                            type="textarea"
                            placeholder="Enter any (additional) pertinent information about the request that will help us direct and resolve the request, such as any applicable names, contact information, or details not entered elsewhere on the form."
                        />
                    }

                </QuestionWrapper>
            }
        </div >
    );
}
