/* eslint-disable no-eval*/
import React, { useState, useEffect, useRef } from 'react';
import { Email, sendEmail, axios } from 'react-mimg';
import validator from 'validator';
import { getMenuPermissions, getUnauthorizedToViewPageAlertMessage, isDeveloper } from '../../../functions/permissions';
import RequestAttachments from './RequestAttachments';
import RequestComments from './RequestComments';
import RequestDetailsBlank from './RequestDetailsBlank';
import RequestDetailsCompleted from './RequestDetailsCompleted';
import RequestFormActionButtons from './RequestFormActionButtons';
import RequestorInfo from './RequestorInfo';
import RequestStatusInfo from './RequestStatusInfo';
import styles from './GenericRequestForm.module.scss';
import flexStyles from '../../../styles/flex.module.scss';
import { RequestWorkflowModel } from '../../../models/requestworkflows/RequestWorkflowModel';
import RequestPropertyInfo from './RequestPropertyInfo';
import { CustomAlertLink } from '../../../models/CustomAlertLink';
import { MimgAlert } from '../../layout/MimgAlert';
import { updateProfileUrl } from '../../profiles/EmployeeProfile/employeeProfileConfig';
import RequestCredentials from './RequestCredentials';
import ChangePropertiesModal from '../modals/ChangePropertiesModal';
import { finalizeEmailList, getRecipientEmailAddresses, replaceStringPlaceholders } from '../../../functions/formHelpers/genericRequestFormEmailHelper';
import ChangeEmailModal from '../modals/ChangeEmailModal';

export default function GenericRequestForm(props) {

    const [filteredRequestTypes, setFilteredRequestTypes] = useState();
    const [selectedProperties, setSelectedProperties] = useState();
    const [requestProperties, setRequestProperties] = useState();
    const [selectedRequestType, setSelectedRequestType] = useState();
    const [showUpdatePropertiesModal, setShowUpdatePropertiesModal] = useState(false)
    const [showChangeEmailModal, setShowChangeEmailModal] = useState(false)
    const [workflow, setWorkflow] = useState();
    const [rejectionStep, setRejectionStep] = useState();
    const [commentStep, setCommentStep] = useState();
    const [addDocStep, setAddDocStep] = useState();
    const [needsInfoStep, setNeedsInfoStep] = useState();
    const [completionStep, setCompletionStep] = useState();
    const [submitStep, setSubmitStep] = useState();
    const [actuallyForEmpCard, setActuallyForEmpCard] = useState() //this is changed when an admin on an enabled form submits the request on behalf of an employee that the form is not for
    const quillRef = useRef();

    let currentWorkflowStep;
    let previousWorkflowStep;

    useEffect(() => {
        setWorkflow(new RequestWorkflowModel());

        let requesterSiteId = props.security.employeeSiteID;
        if (props.security.employeeSiteID === 99999) {
            requesterSiteId = 176;
        }

        const id = props.formId;
        let properties = [];
        if (id !== undefined && validator.isInt(id, { min: 1, max: 999999 })) {
            const requestPropertyIds = props.genericRequestFormModel.genericRequestFormProperties.map(x => x.siteId);
            properties = props.propertyList.filter(x => requestPropertyIds.includes(x.siteId));
        } else {
            if (props.propertyList) {
                properties = props.propertyList.filter(x => x.siteId === requesterSiteId);
            }
        }
        let requestTypesForUser = props.requestTypes.filter(rt =>
            rt.requestTypePermissions.length === 0 || props.isAdmin || isDeveloper() ||
            rt.requestTypePermissions.map(permission => permission.position.position).includes(props.security.employeePosition));

        let filtRequestTypes = [];
        if (props.genericRequestFormModel.requestTypeId) {
            filtRequestTypes = requestTypesForUser.filter(x => x.id === props.genericRequestFormModel.requestTypeId);
        } else {
            filtRequestTypes = requestTypesForUser;
        }

        filtRequestTypes = filtRequestTypes.filter(x => x.inactive !== true);

        setSelectedProperties(properties)
        setRequestProperties(properties)
        setFilteredRequestTypes(filtRequestTypes)
        setWorkflow(workflow)

        //prefill select the first option in the list, unless a request type is specificed, or if this form requires a selection
        if (props.genericRequestFormModel.id > 0 || !props.formPreferences?.requireRequestTypeSelection) {
            if (filtRequestTypes?.length >= 1) { //this shoudl be if there is already a request
                setSelectedRequestType(filtRequestTypes[0]);
            }
        }

        props.onSaveSelectedProperties && props.onSaveSelectedProperties(properties);

    }, [])


    useEffect(() => {
        if (props.genericRequestFormModel && selectedRequestType && filteredRequestTypes) {
            setWorkflowStepInformation(props.genericRequestFormModel, selectedRequestType);
        }
    }, [props.genericRequestFormModel, selectedRequestType, filteredRequestTypes, workflow])

    //if we just loaded the page, we only want the current step.  the currentWorkflowStep should be set anytime there is a status change in the genericWorkflowRequestModel object in an action button (not comment, etc)
    function setWorkflowStepInformation(genericRequestFormModel, selectedRequestType) {
        if (!genericRequestFormModel) {
            genericRequestFormModel = props.genericRequestFormModel;
        }

        let currentStepId = genericRequestFormModel.currentWorkflowStep;
        //the current step needs to reflect the button that was just pressed
        let wf;
        if (selectedRequestType) { //selected request type is only passed in on blank requests since there is not a type yet
            wf = selectedRequestType.approvalWorkflowNavigation
            setWorkflow(wf);
        } else { //if there is not a selectedRequestType passed in, get it from the generic request type model
            wf = filteredRequestTypes.find(x => x.id === genericRequestFormModel.requestTypeId)?.approvalWorkflowNavigation;
            setWorkflow(wf); //we should actually always get it from the generic model, in the event that the workflow associated with a form changes, we only want to use that for future changes.  the existing form will use the old workflow.
        }

        //these three steps are not part of the workflow and should not have a next step associated with them. However,they should be setup as workflow steps so that the RequestWorkflowStepEmailTemplates functionality can be leveraged
        //adding these here avoids needing to set the next step id on the documents and comments since those don't move to the next step.
        //other steps can be variable, but workflows should generally have these steps as a defeault, will look into adding them by default when the workflow is added so that there is consistency
        let rejectionSteps = wf?.requestWorkflowSteps.filter(x => x.associatedButtonName === "Reject");
        let commentSteps = wf?.requestWorkflowSteps.filter(x => x.associatedButtonName === "Comment");
        let docSteps = wf?.requestWorkflowSteps.filter(x => x.associatedButtonName === "Document");
        let needInfoSteps = wf?.requestWorkflowSteps.filter(x => x.associatedButtonName === "Need More Info");
        let completeSteps = wf?.requestWorkflowSteps.filter(x => x.associatedButtonName === "Complete");
        let submitSteps = wf?.requestWorkflowSteps.filter(x => x.associatedButtonName === "Submit");

        setRejectionStep(rejectionSteps?.length > 0 ? rejectionSteps[0] : null);
        setCommentStep(commentSteps?.length > 0 ? commentSteps[0] : null);
        setAddDocStep(docSteps?.length > 0 ? docSteps[0] : null);
        setNeedsInfoStep(needInfoSteps?.length > 0 ? needInfoSteps[0] : null);
        setCompletionStep(completeSteps?.length > 0 ? completeSteps[0] : null);
        setSubmitStep(submitSteps?.length > 0 ? submitSteps[0] : null);


        previousWorkflowStep = currentWorkflowStep; //copy so the emails will send correctly
        if (!previousWorkflowStep && genericRequestFormModel.requestStatus.toLowerCase().includes('submit')) {
            previousWorkflowStep = wf?.requestWorkflowSteps.filter(x => x.stepName.toLowerCase().includes('submit'))[0];
        }

        currentWorkflowStep = wf?.requestWorkflowSteps.filter(x => x.id === currentStepId)[0];
        if (genericRequestFormModel.requestStatus === "Rejected") {
            currentWorkflowStep = rejectionStep;
        } else if (!currentWorkflowStep > 0) {
            currentWorkflowStep = wf?.requestWorkflowSteps[0]; //default is the first step by sortOrder
        }
    }

    function updatePageAlert(pageAlert) {
        props.onUpdatePageAlert(pageAlert);
    }

    function showValidationError(pageAlert) {
        props.onUpdatePageAlert(pageAlert);
    }

    function onSaveSelectedProperties(requestProperties, selectedProperties) {
        setRequestProperties(requestProperties);
        setSelectedProperties(selectedProperties);

        //update the main form to get the info for prefilling property data
        if (props.onSaveSelectedProperties) {
            props.onSaveSelectedProperties(selectedProperties)
        }
    }

    function onRequestTypeChange(selectedRequestType) {
        setWorkflowStepInformation(props.genericRequestFormModel, selectedRequestType);
        setSelectedRequestType(selectedRequestType)

        props.onRequestTypeChange && props.onRequestTypeChange(selectedRequestType);
    }

    function onHandleRequestUpdate(genericRequestFormModel, pageAlert, updateType) {
        let selectedRequestType = filteredRequestTypes.filter(x => x.id === genericRequestFormModel.requestTypeId)[0];
        setWorkflowStepInformation(genericRequestFormModel, selectedRequestType);
        sendStatusUpdateEmails(genericRequestFormModel, pageAlert, updateType);
        setShowUpdatePropertiesModal(false)
    }

    function updateGenericRequestFormModel(genericRequestFormModel, closeModals) {
        props.updateOuterForm(genericRequestFormModel, null);
        if (closeModals) {
            setShowUpdatePropertiesModal(false)
        }
    }

    async function sendStatusUpdateEmails(genericRequestFormModel, pageAlert, action) {
        let emails; //the emails should come from the next step that is being triggered, unless it is a rejection, comment, document, or "Need More Info"
        let previousStepId;
        switch (action) {
            case "Reject":
                emails = rejectionStep.requestWorkflowStepEmailTemplates;
                previousStepId = genericRequestFormModel.currentWorkflowStep;
                break;
            case "Comment":
                emails = commentStep.requestWorkflowStepEmailTemplates;
                previousStepId = genericRequestFormModel.currentWorkflowStep;
                break;
            case "Document":
                emails = addDocStep.requestWorkflowStepEmailTemplates;
                previousStepId = genericRequestFormModel.currentWorkflowStep;
                break;
            case "Need More Info":
                emails = needsInfoStep.requestWorkflowStepEmailTemplates;
                previousStepId = genericRequestFormModel.currentWorkflowStep;
                break;
            case "Complete":
                let sameStepWorkflows = [18]; //Some issues with the IT setup workflow.  The complete step there links to itself, which is unlike other workflows.  We only want to run this code on workflows like that, others (especially from before the IT setup form was created should follow the same workflow as the 'default' step)
                if (sameStepWorkflows.includes(completionStep.workflowId)) {
                    emails = completionStep.requestWorkflowStepEmailTemplates;
                    previousStepId = genericRequestFormModel.currentWorkflowStep;
                } else { //If the completion comes from another step, there is not a workflow
                    emails = currentWorkflowStep.requestWorkflowStepEmailTemplates;
                    previousStepId = previousWorkflowStep?.id;
                }
                break;
            default:
                emails = currentWorkflowStep.requestWorkflowStepEmailTemplates;
                if (action.toLowerCase() === 'submit') {
                    previousStepId = submitStep?.id ? submitStep?.id : 0;
                } else {
                    previousStepId = previousWorkflowStep ? previousWorkflowStep?.id : 0;
                }

                break;
        }

        if (emails.length > 0) {
            for (let i = 0; i < emails.length; i++) {
                let emailData = emails[i];
                if (emailData.previousStepId === previousStepId || emailData.previousStepId === 99999) { //only send the emails that are associated with the previous step, or associated with any step which is 99999
                    let email = new Email();
                    let toEmails = getRecipientEmailAddresses(genericRequestFormModel, emailData, "to", props.propertyList, filteredRequestTypes);
                    let ccEmails = getRecipientEmailAddresses(genericRequestFormModel, emailData, "cc", props.propertyList, filteredRequestTypes);

                    email.subject = await replaceStringPlaceholders(emailData.emailSubject, genericRequestFormModel, props.propertyList, selectedRequestType, props.formType, currentWorkflowStep);
                    email.body = await replaceStringPlaceholders(emailData.emailBody, genericRequestFormModel, props.propertyList, selectedRequestType, props.formType, currentWorkflowStep);
                    email.fromEmailAddress = emailData.emailFrom ? emailData.emailFrom : "mimgus@monarchinvestment.com"
                    email.sendAsHtml = true;
                    email.attachments = [];
                    email.userId = props.security.userId;
                    email.source = "Employee Website";
                    email.sendNow = true;
                    email.genericRequestId = genericRequestFormModel.id;

                    if (emailData.emailSarHiringManagers === 'to' || emailData.emailSarHiringManagers === 'cc') { //this had to be separate from the 
                        axios.get(`api/ItSetup/GetSystemAccessRequestHiringManagersForItSetupForm?formid=${genericRequestFormModel.id}`).then(response => {
                            response.data.map(x => {
                                if (emailData.emailSarHiringManagers === 'to') {
                                    toEmails.push(x.email)
                                } else {
                                    ccEmails.push(x.email)
                                }
                            })

                            email.toEmailAddresses = finalizeEmailList(emailData, toEmails, props.sendEmails);
                            email.ccEmailAddresses = finalizeEmailList(emailData, ccEmails, props.sendEmails);
                            finishSendingEmails(email, genericRequestFormModel, pageAlert);
                        })
                    } else {
                        email.toEmailAddresses = finalizeEmailList(emailData, toEmails, props.sendEmails);
                        email.ccEmailAddresses = finalizeEmailList(emailData, ccEmails, props.sendEmails);
                        finishSendingEmails(email, genericRequestFormModel, pageAlert);
                    }
                }
            }
        } else {
            pageAlert.AlertText = pageAlert.AlertText + " No emails are sent with this workflow step."
            props.updateOuterForm(genericRequestFormModel, pageAlert);
        }


    }

    function finishSendingEmails(email, genericRequestFormModel, pageAlert) {

        if (email.toEmailAddresses === "" && email.ccEmailAddresses === "") {
            email.toEmailAddresses = "mimgus@monarchinvestment.com";
        }

        //the prefix xyz_ avoids sending emails for forms that are in testing. See getEmailAddresses
        email.toEmailAddresses = email.toEmailAddresses === "xyz_" ? "" : email.toEmailAddresses;
        email.ccEmailAddresses = email.ccEmailAddresses === "xyz_" ? "" : email.ccEmailAddresses;

        sendEmail(email).then((emailResponse) => {
            pageAlert.AlertText = pageAlert.AlertText + " 1 or more emails were sent successfully. Press the Emails button in the Request Actions section to see all emails associated with this form.";
            genericRequestFormModel.emailsSents.push(emailResponse.data);
            props.updateOuterForm(genericRequestFormModel, pageAlert);
        }).catch((error) => {
            console.log(error);
            pageAlert.AlertText = pageAlert.AlertText + ", however there was an error sending one or more emails.";
            pageAlert.AlertColor = "danger";
            props.updateOuterForm(genericRequestFormModel, pageAlert);
        }).finally(() => {
            //        props.updateOuterForm(genericRequestFormModel, pageAlert);
        })
    }


    if (!props.genericRequestFormModel) {
        return <div>Loading...</div>;
    }

    if (filteredRequestTypes?.length === 0) {
        const links = [
            new CustomAlertLink(updateProfileUrl(true, props.security.empID), "You may try to reset your permissions by clicking this link, then looking for the Update My Permissions button near the top of the page. Then, reload this page."),
        ]
        return (
            <MimgAlert alertInfo={getUnauthorizedToViewPageAlertMessage(links)} />
        )
    }

    return (
        <>
            <div className={flexStyles.rowFlexContainer}>
                <div className={flexStyles.flexHalfIfSmall}>
                    <RequestorInfo genericRequestFormModel={props.genericRequestFormModel} employeeSecurity={props.security} formPreferences={props.formPreferences} onUpdateActuallyForEmployeeCard={setActuallyForEmpCard} isAdmin={props.isAdmin} employeePhone={props.employeePhone} onSave={updateGenericRequestFormModel}  />
                </div>
                <div className={flexStyles.flexHalfIfSmall}>
                    {props.genericRequestFormModel.id > 0 && workflow &&
                        <RequestStatusInfo genericRequestFormModel={props.genericRequestFormModel} workflow={workflow} currentStep={currentWorkflowStep} isAdmin={props.isAdmin} formPreferences={props.formPreferences} propertyList={props.propertyList} onUpdatePageAlert={updatePageAlert} onUpdateGenericRequestFormModel={updateGenericRequestFormModel} />
                    }
                </div>
            </div>

            {props.propertyList && requestProperties && !props.formPreferences?.hideRequestPropertySelection &&
                <RequestPropertyInfo
                    onSaveSelectedProperties={onSaveSelectedProperties}
                    properties={props.propertyList}
                    requestProperties={requestProperties}
                    isNewRequest={props.genericRequestFormModel.requestStatus === ""}
                    allowMultiPropertySelect={props.formPreferences?.allowMultiPropertySelect || selectedRequestType?.allowMultipleProperties}
                    onShowChangePropertiesModal={() => setShowUpdatePropertiesModal(true)}
                    isAdmin={props.isAdmin} />

            }
            {/*If the form was not yet submitted, the user has the ability to submit new data.  If it was already submitted, then show the submitted data. */}
            <div className={`${flexStyles.paddingBottom10} ${flexStyles.paddingTop10}`}>
                <h4 className={styles.sectionHeader}>Request Type & Details</h4>
                {props.genericRequestFormModel.requestStatus === '' && filteredRequestTypes?.length > 0 &&
                    <RequestDetailsBlank
                        requestTypes={filteredRequestTypes?.filter(x => x.inactive == null || x.inactive === false)}
                        selectedRequestTypeId={props.genericRequestFormModel.requestTypeId > 0 ? props.genericRequestFormModel.requestTypeId : null}
                        onRequestTypeChange={onRequestTypeChange}
                        selectedProperties={selectedProperties}
                        onUpdateSelectedProperties={onSaveSelectedProperties}
                        defaultValueList={props.defaultValueList ? props.defaultValueList : null}
                        isAdmin={props.isAdmin}
                        onSelectEmployee={props.onSelectEmployee}
                        onSelectEmployee2={props.onSelectEmployee2}
                        propertyEmployeesId={props.propertyEmployeesId}
                        propertyList={props.propertyList}
                        formPreferences={props.formPreferences}
                        quillRef={quillRef}
                        myEmployees={props.myEmployees}
                        requestDetailKey1={props.requestDetailKey1}
                    />
                }

                {props.genericRequestFormModel.requestStatus !== '' &&
                    <RequestDetailsCompleted genericRequestFormModel={props.genericRequestFormModel} requestTypes={filteredRequestTypes} isAdmin={props.isAdmin} formPreferences={props.formPreferences} onUpdateRequestType={props.updateOuterForm} formType={props.formType} onShowChangePropertiesModal={() => setShowUpdatePropertiesModal(true)} onShowChangeEmailModal={() => setShowChangeEmailModal(true)} propertyList={props.propertyList} />
                }
            </div>

            {props.genericRequestFormModel.genericRequestFormComments.length > 0 &&
                <RequestComments comments={props.genericRequestFormModel.genericRequestFormComments} isAdmin={props.isAdmin} requestTypes={filteredRequestTypes} />
            }

            {props.genericRequestFormModel.genericRequestFormDocuments.length > 0 &&
                <RequestAttachments attachmentList={props.genericRequestFormModel.genericRequestFormDocuments} formType={props.formType} />
            }

            {props.genericRequestFormModel.genericRequestResponses.filter(x => x.responseType === 'Credential').length > 0 &&
                <RequestCredentials credentials={props.genericRequestFormModel.genericRequestResponses.filter(x => x.responseType === 'Credential')} />
            }

            {selectedRequestType &&
                <RequestFormActionButtons
                    onSubmitButtonClick={onHandleRequestUpdate}
                    showValidationError={updatePageAlert}
                    requestTypes={filteredRequestTypes}
                    propertyList={props.propertyList}
                    requestProperties={requestProperties}
                    formType={props.formType}
                    genericRequestFormModel={props.genericRequestFormModel}
                    onSaveWithEmails={onHandleRequestUpdate}
                    onSaveWithoutEmails={updateGenericRequestFormModel}
                    updateGenericRequestFormModel={updateGenericRequestFormModel}
                    requestTypes={filteredRequestTypes}
                    availableAssignees={props.availableAssignees}
                    formPreferences={props.formPreferences}
                    selectedRequestType={selectedRequestType}
                    isAdmin={props.isAdmin}
                    actuallyForEmpCard={actuallyForEmpCard}
                    employeePhone={props.employeePhone} /*Required for IT Form */
                    quillRef={quillRef} />
            }

            <ChangePropertiesModal
                genericRequestFormModel={props.genericRequestFormModel}
                isOpen={showUpdatePropertiesModal}
                formPreferences={props.formPreferences}
                toggle={() => setShowUpdatePropertiesModal(false)} />

            <ChangeEmailModal
                genericRequestFormModel={props.genericRequestFormModel}
                isOpen={showChangeEmailModal}
                formPreferences={props.formPreferences}
                toggle={() => setShowChangeEmailModal(false)}
            />

        </>
    )
}
