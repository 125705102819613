import React, { useEffect, lazy, Suspense } from 'react';
import { isIE } from 'react-device-detect';
import { IEnotSupported, NotFoundPage, axios, getNowMountainTime } from 'react-mimg';
import 'react-mimg/dist/index.css';
import { Route, useLocation, Routes, useParams } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { getSearchData } from './functions/search/searchData';
import Logo from './images/mimgLogo3.png';
import ReactGA from 'react-ga4';
import { GetAMRMPropertySummary, GetEmployeesList, GetExtensionList, GetFMLAEligibility, GetFranktownContacts, GetMIMGTransactions, GetPropertyList, GetTerminationsList } from './functions/CSVs';
import UserMaint from './pages/admin/UserMaint';
import PayrollRequestForm from './pages/requestForms/PayrollRequestForm';
import { getEmployeeSecurity } from './functions/permissions';
import CorporateServicesV2 from './pages/requestForms/CorporateServicesV2';

const Layout = lazy(() => import('./components/layout/Layout'))

//Admin
const LogViewer = lazy(() => import('./pages/admin/LogViewer'));
const PageViewAdmin = lazy(()=> import('./pages/admin/PageViewAdmin'))
const RequestTypeMaintenance = lazy(() => import('./pages/admin/RequestTypeMaintenance'));
const RequestWorkflowMaintenance = lazy(() => import('./pages/admin/RequestWorkflowMaintance'));
const NotificationEmailAddresses = lazy(() => import('./pages/admin/NotificationEmailAddresses'))
const EmployeeDocumentMaintenance = lazy(() => import('./pages/admin/EmployeeDocumentMaintenance'))
const CannotOpenFile = lazy(() => import('./pages/admin/CannotOpenFile'))
const JobTitles = lazy(() => import('./pages/admin/JobTitles'));
const VendorDocsAdmin = lazy(() => import('./pages/admin/VendorDocsAdmin'));
const PropertyMaintenance = lazy(() => import('./pages/admin/AddNewProperty'));
const PositionMaint = lazy(() => import('./pages/admin/PositionMaint'));
const CreateEmployee = lazy(() => import('./pages/admin/CreateEmployee'));
const InstagramApiSetup = lazy(() => import('./pages/admin/integrations/InstagramApiSetup'));
const PropertyMediaMaint = lazy(() => import('./pages/admin/PropertyMediaMaint'))
const StaffMaint = lazy(() => import('./pages/admin/StaffMaint'))
const ThumbsUpReport = lazy(() => import('./pages/profiles/Reports/ThumbsUpReport'))
const TrainingCalendar = lazy(() => import('./pages/admin/TrainingCalendar'))
const DuplicatePrismIds = lazy(() => import('./pages/admin/DuplicatePrismIds'))
const PropertyProgramAdmin = lazy(() => import('./pages/admin/PropertyProgramAdmin'))
const ReferralBonusAdmin = lazy(() => import('./pages/admin/payroll/ReferralBonusList'))
const CacheAdmin = lazy(() => import('./pages/admin/CacheAdmin'))
const UkgAdmin = lazy(() => import('./pages/admin/UKG/UkgAdmin'))
const EmailAddressMaintenance = lazy(() => import('./pages/admin/UKG/EmailAddressMaintenance'))

//Profiles
const EmployeeProfile = lazy(() => import('./pages/profiles/EmployeeProfile'));
/*const EditEmployeeProfile = lazy(() => import('./pages/profiles/EditEmployeeProfile'));*/
const CorporateProfile = lazy(() => import('./pages/profiles/CorporateProfile'));
const PropertyProfile = lazy(() => import('./pages/profiles/PropertyProfile'));
const MapSearch = lazy(() => import('./pages/profiles/MapSearch'));
const History = lazy(() => import('./pages/profiles/History'));
const AreaContacts = lazy(() => import('./pages/profiles/AreaContacts'));
const VendorContacts = lazy(() => import('./pages/profiles/VendorContacts'));
const VendorContact = lazy(() => import('./pages/profiles/VendorContact'));
const RegionStars = lazy(() => import('./pages/profiles/RegionStars'));
const MyEmployees = lazy(() => import('./pages/profiles/MyEmployees/MyEmployees'));
const PropertyList = lazy(() => import('./pages/profiles/PropertyList'));
const RequestFormAnalytics = lazy(() => import('./pages/profiles/Reports/RequestFormAnalytics'));


//Requests
const CorporateServicesRequestForm = lazy(() => import('./pages/requestForms/CorporateServicesV2'));
const VendorSetupFormV2 = lazy(() => import('./pages/requestForms/VendorSetupForm'));
const TrainingForm = lazy(() => import('./pages/requestForms/TrainingForm'));
const NominationsForm = lazy(() => import('./pages/requestForms/NominationsForm'));
const TeamRelationsForm = lazy(() => import('./pages/requestForms/TeamRelationsForm'));
const CreditCardRequestForm = lazy(() => import('./pages/requestForms/CreditCardForm'));
const NewRecruitingForm = lazy(() => import('./pages/requestForms/RecruitingForm'));
const ItSetupForm = lazy(() => import('./pages/requestForms/ItSetupForm'));
const Payroll = lazy(() => import('./pages/requestForms/PayrollRequestForm'));
const LicenseToOccupyFormNew = lazy(() => import('./pages/requestForms/LicenseToOccupyFormNew'));
const LicenseToOccupyInstructions = lazy(() => import('./pages/requestForms/LicenseToOccupyInstructions'));
const MarketingRequestForm = lazy(() => import('./pages/requestForms/MarketingForm'))
const DriversForm = lazy(() => import('./pages/requestForms/DriversForm'));
const DriverList = lazy(() => import('./pages/profiles/DriverList'));
const YardiSupportForm = lazy(() => import('./pages/requestForms/YardiSupportForm'))
const YardiConsolidatedQueue = lazy(() => import('./pages/requestForms/AlternateQueues/YardiConsolidatedQueue'))
const ITSupportForm = lazy(() => import('./pages/requestForms/ITSupportForm'))
const ITConsolidatedQueue = lazy(() => import('./pages/requestForms/AlternateQueues/ITConsolidatedQueue'))
const MarketingConsolidatedQueue = lazy(() => import('./pages/requestForms/AlternateQueues/MarketingConsolidatedQueue'))
const HrRequestForm = lazy(() => import('./pages/requestForms/HrRequestForm'));
const HrRequestQueue = lazy(() => import('./pages/requestForms/AlternateQueues/HrQueue'));
const CorporateServicesQueue = lazy(() => import('./pages/requestForms/AlternateQueues/CorporateServicesQueue'));

//Reviews
const MidYearReviewForm = lazy(() => import('./components/profiles/MyEmployees/MidYearReviewForm'));
//Resources
const AllLinks = lazy(() => import('./pages/resources/AllLinks'));
const CharitableGiving = lazy(() => import('./pages/resources/CharitableGiving'));
const Resources = lazy(() => import('./pages/resources/Links'));
const WelcomeVideo = lazy(() => import('./pages/resources/WelcomeVideo'));

//Mission
const Mission = lazy(() => import('./pages/resources/Mission'))

//Search (component used as a page, it is also called within a modal if clicked on from the navbar.  This is to handle searches from the old site)
const SearchPage = lazy(() => import('./pages/search/SearchPage'));

//CSVs
const CSVs = lazy(() => import('./functions/CSVs'))

//Logging
const NotFoundLogWrapper = lazy(() => import('./components/logging/NotFoundLogWrapper'));

ReactGA.initialize('G-PX4GF31HG2');

export default function App() {
    require('es6-object-assign').polyfill();
    const location = useLocation();
    const params = useParams();
    const user = getEmployeeSecurity()

    useEffect(() => {
        const logPageView = async () => {
            if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
                let page = location.pathname.toLowerCase() + location.search.toLowerCase();
                ReactGA.send("pageview", page);
            }

            const url = window.location.href;
            const queryString = location.search.toLowerCase();
            const path = location.pathname;

            let idParameter = null;
            const pathSegments = path.split('/').filter(segment => segment.trim() != '');
            const idRegex = /[?&](id|siteid)=([^&]+)/i; //extract values for parameters id or siteid from query
            const idMatch = queryString.match(idRegex);
            if (idMatch) {
                idParameter = idMatch[2];
            } else if (pathSegments.length > 2) {
                idParameter = pathSegments.pop();
            }

            const updatedPath = pathSegments.join('/');

            let actionParameter = null;
            const tabRegex = /[?&]tab=([^&]+)/i;
            const tabMatch = queryString.match(tabRegex);
            if (tabMatch) {
                actionParameter = `?tab=${tabMatch[1]}`;
            }

            let view = {
                userId: user?.userId,
                fullUrl: url,
                propertyEmployeesId: user?.empID,
                path: updatedPath, 
                queryString: location.search,
                idParameter: idParameter,
                actionParameter: actionParameter
            };

            await axios.post('api/PageViewLog/LogPageView', view);
        };

        logPageView();
    }, [location]);


    useEffect(() => {
        getSearchData();
    }, []) //check for needed refresh on all page loads, but not events within the same page

    if (isIE) {
        return (<IEnotSupported logo={Logo} />);
    }

    return (
        <Suspense fallback={<div />}>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<EmployeeProfile />} />  {/* mimg.us/v2 - because why not?*/}
                    {/*Profiles*/}
                    <Route exact path='/profiles/history' element={<History />} />
                    <Route exact path='/profiles/areacontacts' element={<AreaContacts />} />
                    <Route exact path='/profiles/propertyList' element={<PropertyList />} />
                    <Route exact path='/profiles/property' element={<PropertyList />} />
                    <Route exact path='/profiles/vendorcontacts' element={<VendorContacts />} />
                    <Route exact path='/profiles/ThumbsUpReport' element={<ThumbsUpReport />} />
                    <Route exact path='/profiles/RequestFormAnalytics' element={<RequestFormAnalytics />} />
                    {/*<Route exact path='/profiles/managermap' element={<ManagerMap />} />*/}
                    <Route exact path='/profiles/employee' element={<EmployeeProfile />} />
                    <Route exact path='/profiles/corporateprofile' element={<CorporateProfile />} />
                    <Route exact path='/profiles/property/:id' element={<PropertyProfile />} />
                    <Route exact path='/profiles/mapsearch' element={<MapSearch />} />
                    <Route exact path='/profiles/vendorcontact' element={<VendorContact location={location} />} />
                    <Route exact path='/profiles/vendorcontact/:id' element={<VendorContact location={location} />} />
                    <Route exact path='/profiles/regionstars' element={<RegionStars />} />
                    <Route exact path='/profiles/DriverList' element={<DriverList />} />
                    {/*<Route exact path='/profiles/EditEmployeeProfile' element={<EditEmployeeProfile />} />*/}
                    <Route exact path='/profiles/MyEmployees' element={<MyEmployees />} />

                    {/*Resources*/}
                    <Route exact path='/allresources' element={<AllLinks />} />
                    <Route exact path="/resources/:id" element={<Resources location={location} />} />
                    <Route exact path='/charitablegiving' element={<CharitableGiving />} />
                    <Route exact path='/welcomevideo' element={<WelcomeVideo />} />

                    {/*Request Forms*/}
                    <Route path='/requests'>
                        <Route path='vendorsetup' element={<VendorSetupFormV2 />}>
                            <Route path=':action' element={<VendorSetupFormV2 />} />
                        </Route>
                        <Route path='training' element={<TrainingForm />}>
                            <Route path=':action' element={<TrainingForm />} />
                        </Route>
                        <Route path='corporateservices' element={<CorporateServicesRequestForm/>} >
                            <Route path=':action' element={<CorporateServicesRequestForm />} />
                        </Route>
                        <Route path='yardi' element={<YardiSupportForm />} >
                            <Route path=':action' element={<YardiSupportForm />} />
                        </Route>
                        <Route exact path='yardi/list' element={<YardiConsolidatedQueue location={location} />} />

                        <Route path='IT' element={<ITSupportForm />} >
                            <Route path=':action' element={<ITSupportForm />} />
                        </Route>
                        <Route exact path='IT/list' element={<ITConsolidatedQueue location={location} />} />

                        <Route path='nominations' element={<NominationsForm />} >
                            <Route path=':action' element={<NominationsForm />} />
                        </Route>
                        <Route path='teamrelations' element={<TeamRelationsForm />} >
                            <Route path=':action' element={<TeamRelationsForm />} />
                        </Route>
                        <Route path='Payroll' element={<PayrollRequestForm />} >
                            <Route path=':action' element={<PayrollRequestForm />} />
                        </Route>
                        <Route path='creditcards' element={<CreditCardRequestForm />} >
                            <Route path=':action' element={<CreditCardRequestForm />} />
                        </Route>
                        <Route path='recruiting' element={<NewRecruitingForm />} >
                            <Route path=':action' element={<NewRecruitingForm />} />
                        </Route>
                        <Route path='itsetup' element={<ItSetupForm />} >
                            <Route path=':action' element={<ItSetupForm />} />
                        </Route>
                        <Route path='payroll' element={<Payroll />} >
                            <Route path=':action' element={<Payroll />} />
                        </Route>
                        <Route path='hr' element={<HrRequestForm />} >
                            <Route path=':action' element={<HrRequestForm />} />
                        </Route>
                        <Route exact path='hr/list' element={<HrRequestQueue location={location} />} />
                        <Route path='licensetooccupy' element={<LicenseToOccupyFormNew location={location} />} >
                            <Route path=':action' element={<LicenseToOccupyFormNew location={location} />} />
                        </Route>
                        <Route path='licensetooccupyinstructions' element={<LicenseToOccupyInstructions location={location} />} >
                            <Route exact path='licensetooccupyinstructions' element={<LicenseToOccupyInstructions location={location} />} />
                        </Route>
                        <Route path='drivers' element={<DriversForm location={location} />} >
                            <Route path=':action' element={<DriversForm location={location} />} />
                        </Route>
                        <Route path='marketing' element={<MarketingRequestForm />} >
                            <Route path=':action' element={<MarketingRequestForm location={location} />} />
                        </Route>
                        <Route exact path='marketing/list' element={<MarketingConsolidatedQueue location={location} />} />
                        <Route exact path='corporateservices/newqueuetest' element={<CorporateServicesQueue location={location} />} />

                    </Route>

                    <Route path='/requests/corporateservices/:action?' element={<CorporateServicesRequestForm />} />
                    <Route path='/requests/training/:action?' element={<TrainingForm />} />
                    <Route path='/requests/nominations/:action?' element={<NominationsForm />} />
                    <Route path='/requests/teamrelations/:action?' element={<TeamRelationsForm />} />
                    <Route path='/requests/itsetup/:action?' element={<ItSetupForm />} />
                    <Route path='/requests/licensetooccupy/:action?' element={<LicenseToOccupyFormNew />} />
                    <Route path='/requests/marketing/:action?' element={<MarketingRequestForm />} />


                    {/*Admin*/}
                    <Route path='admin'>
                        <Route exact path='UserMaint' element={<UserMaint />} />
                        <Route exact path='PageViewAdmin' element={<PageViewAdmin />} />
                        <Route exact path='logviewer' element={<LogViewer />} />
                        <Route exact path='requesttypemaint' element={<RequestTypeMaintenance />} />
                        <Route exact path='requestworkflowmaintenance' element={<RequestWorkflowMaintenance />} />
                        <Route exact path='NotificationEmailAddresses' element={<NotificationEmailAddresses />} />
                        <Route exact path='VendorDocsAdmin' element={<VendorDocsAdmin />} />
                        <Route exact path='EmployeeDocumentMaintenance' element={<EmployeeDocumentMaintenance />} />
                        <Route exact path='CannotOpenFile' element={<CannotOpenFile />} />
                        <Route exact path='JobTitles' element={<JobTitles />} />
                        <Route exact path='AddNewProperty' element={<PropertyMaintenance />} />
                        <Route exact path='PositionMaint' element={<PositionMaint />} />
                        <Route exact path='PropertyMediaMaint' element={<PropertyMediaMaint />} />
                        <Route exact path='CreateEmployee' element={<CreateEmployee />} />
                        <Route exact path='InstagramApiSetup' element={<InstagramApiSetup />} />
                        <Route exact path='StaffMaint' element={<StaffMaint />} />
                        <Route exact path='TrainingCalendar' element={<TrainingCalendar />} />
                        <Route exact path='DuplicatePrismIds' element={<DuplicatePrismIds />} />
                        <Route exact path='ReferralBonusAdmin' element={<ReferralBonusAdmin />} />
                        <Route exact path='CacheAdmin' element={<CacheAdmin />} />
                        <Route exact path='UkgAdmin' element={<UkgAdmin />} />
                        <Route exact path='EmailAddressMaintenance' element={<EmailAddressMaintenance />} />
                    </Route>

                    <Route exact path='/admin/PropertyProgramAdmin' element={<PropertyProgramAdmin />} />
                    <Route exact path='/admin/AddNewProperty' component={PropertyMaintenance} />

                    {/*Reviews*/}
                    <Route path="/reviews">
                        <Route exact path='midyearreview' element={<MidYearReviewForm />} />
                    </Route>

                    {/*Mission*/}
                    <Route exact path='/mission' element={<Mission />} />

                    {/*Search*/}
                    <Route exact path='/search' element={<SearchPage />} />

                    {/*CSVs*/}
                    <Route path='/CSVs'>
                        <Route exact path='GetFranktownContacts' element={<GetFranktownContacts />} />
                        <Route exact path='GetExtensionList' element={<GetExtensionList />} />
                        <Route exact path='GetTerminationsList' element={<GetTerminationsList />} />
                        <Route exact path='GetMIMGTransactions' element={<GetMIMGTransactions />} />
                        <Route exact path='GetEmployeesList' element={<GetEmployeesList />} />
                        <Route exact path='GetPropertyList' element={<GetPropertyList />} />
                        <Route exact path='GetAMRMPropertySummary' element={<GetAMRMPropertySummary />} />
                        <Route exact path='GetFMLAEligibility' element={<GetFMLAEligibility />} />
                    </Route>

                    <Route path="*" element={<NotFoundLogWrapper logo={Logo} />} />
                </Route>
            </Routes >
        </Suspense>
    );
}
